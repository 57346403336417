import { useIntl } from 'react-intl';

import loginMessages from 'Login/messages';
import Button from 'common/Button';
import { LoginMethod, PhaseId } from 'types/AuthenticationV2';
import { AppDispatch } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { getLoginMethodSelectionThunk, loginMethodSelectionThunk } from 'store/auth/slice';
import makeSelectAuthRequired from 'store/auth/selectors';
import { handleCatch } from 'utils/errors';
import { FC, useEffect } from 'react';
import { OverrideNavigation } from 'Login/AuthV2/types';

const LoginMethodSelection: FC<OverrideNavigation> = ({ overrideNavigation }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const { loginMethodSelectError, phaseId, loginMethods: data } = useSelector(makeSelectAuthRequired());

  useEffect(() => {
    const init = async () => {
      // if the screen is reloaded through a channel action (user logs in with the same ID from another tab), this hook will fire automatically.
      // This is why we will check the phaseId to see if we need to fetch the login methods, and not end up with an error
      if (phaseId === PhaseId.LOGIN_METHOD_SELECTION) {
        await dispatch(getLoginMethodSelectionThunk());
      }
    };
    init().then();
  }, [phaseId]);

  const handleLoginMethodSelection = async (payload: LoginMethod) => {
    try {
      // TODO: Maybe RESET STORE
      await dispatch(loginMethodSelectionThunk({ loginMethodId: payload, overrideNavigation })).unwrap();
    } catch (error) {
      handleCatch(error);
    }
  };

  return (
    <div className="d-flex flex-column">
      <h3 className="text-center">{formatMessage(loginMessages.loginMethodSelection)}</h3>
      {loginMethodSelectError?.message && <p className="text-danger text-center">{loginMethodSelectError.message}</p>}
      {data?.map((method) => (
        <Button secondary className="m-1" key={method.id} onClick={() => handleLoginMethodSelection(method.id)}>
          {method.label}
        </Button>
      ))}
    </div>
  );
};

export default LoginMethodSelection;
