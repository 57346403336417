import { Form, Formik } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';

import Button from 'common/Button';
import HeaderLabel from 'common/HeaderLabel';
import { Input } from 'common/form/Formik';

import messages from 'Login/messages';

type SmsVerificationFields = {
  token: string;
};

type Props = {
  attempts: number;
  onSubmit: (payload: { token: string }) => void;
  loading?: boolean;
};

const SmsVerification: React.FC<Props> = ({ onSubmit, loading }) => {
  const { formatMessage } = useIntl();
  const initialValues: SmsVerificationFields = {
    token: '',
  };

  return (
    <Formik initialValues={initialValues} onSubmit={(values) => onSubmit(values)}>
      {({ isValid, isSubmitting }) => (
        <Form>
          <HeaderLabel label={formatMessage(messages.enterSmsToken)} />
          <Input
            name="token"
            type="text"
            label={formatMessage(messages.smsToken)}
            data-cy="sms_verification_input"
            required
            minLength={6}
            maxLength={6}
            autoComplete="off"
            autoFocus
          />
          <Button
            className="w-100 mt-2"
            secondary
            type="submit"
            disabled={isSubmitting || !isValid}
            data-cy="sms_verification_submit"
            loading={loading}
          >
            {formatMessage(messages.send)}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default SmsVerification;
