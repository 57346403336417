import { createSlice, createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { getDeviceRegistrations, postTenantSelection } from 'services/authentication/loginV2';
import { State } from 'types/State';
import { Tenant } from 'types/AuthenticationV2';
import { checkAuthV2StatusThunk } from 'store/auth/slice';
import store from 'store';

export const TENANT_SELECTION_REDUCER_NAME = 'tenantSelection';

export const fetchDeviceRegistrationsThunk = createAsyncThunk(
  `${TENANT_SELECTION_REDUCER_NAME}/fetchDeviceRegistrations`,
  async () => {
    const response = await getDeviceRegistrations();
    return response.data;
  },
);

export const postTenantSelectionThunk = createAsyncThunk(
  `${TENANT_SELECTION_REDUCER_NAME}/postTenantSelection`,
  async (tenant: Tenant) => {
    await postTenantSelection(tenant);
    store.dispatch(checkAuthV2StatusThunk());
  },
);

export type TenantSelectionState = {
  tenants: { label: string; name: string }[];
  state: State;
  error: null | SerializedError;
};

export const initialState: TenantSelectionState = {
  tenants: [],
  state: State.NOT_STARTED,
  error: null,
};

const tenantSelectionSlice = createSlice({
  name: TENANT_SELECTION_REDUCER_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDeviceRegistrationsThunk.pending, (draft) => {
      draft.state = State.PENDING;
      draft.error = null;
    });
    builder.addCase(fetchDeviceRegistrationsThunk.fulfilled, (draft, action) => {
      draft.state = State.SUCCESS;
      draft.tenants = action.payload;
      draft.error = null;
    });
    builder.addCase(fetchDeviceRegistrationsThunk.rejected, (draft, action) => {
      draft.state = State.FAILED;
      draft.error = action.error;
    });
    builder.addCase(postTenantSelectionThunk.pending, (draft) => {
      draft.state = State.PENDING;
      draft.error = null;
    });
    builder.addCase(postTenantSelectionThunk.fulfilled, (draft) => {
      draft.state = State.SUCCESS;
      draft.error = null;
    });
    builder.addCase(postTenantSelectionThunk.rejected, (draft, action) => {
      draft.state = State.FAILED;
      draft.error = action.error;
    });
  },
});

export default tenantSelectionSlice.reducer;
