/* medicine constants */

import { MessageDescriptor } from 'react-intl';
import commonTranslations from 'translations/CommonTranslations';
import messages from 'sharedComponents/MedicineList/messages';

export const medicineColumnNames = [
  'inDistributor',
  'dosageDistribution',
  'givenAt',
  'name',
  'dosageText',
  'lastModifier',
  'purpose',
  'startDate',
  'endDate',
  'notice',
  'deletedAt',
  'deleterUser',
  'lastUpdatedAt',
  'modifyReason',
  'deleteReason',
  'dosageFields',
  'actions',
];

export const medicineColumnsTranslations: Record<string, MessageDescriptor> = {
  inDistributor: messages.dispenser,
  dosageDistribution: messages.portionDistribution,
  givenAt: commonTranslations.dateAndTime,
  name: commonTranslations.name,
  dosageText: messages.dosage,
  purpose: messages.purpose,
  startDate: commonTranslations.startDate,
  endDate: commonTranslations.endDate,
  notice: messages.notes,
  deletedAt: commonTranslations.deletedAt,
  deleterUser: commonTranslations.deletedBy,
  lastUpdatedAt: commonTranslations.lastEdited,
  modifyReason: commonTranslations.reason,
  deleteReason: commonTranslations.reason,
  actions: commonTranslations.actions,
  dosageFields: messages.dosageUnit,
  lastModifier: messages.lastModifier,
};
export const GET_MEDICINE_LIST_SUCCESS = 'app/MedicineList/GET_MEDICINE_LIST_SUCCESS';
export const GET_MEDICINE_LIST_FAILED = 'app/MedicineList/GET_MEDICINE_LIST_FAILED';

export const GET_DISTRIBUTION_DESCRIPTOR_SUCCESS = 'app/MedicineList/GET_DISTRIBUTION_DESCRIPTOR_SUCCESS';
export const GET_DISTRIBUTION_DESCRIPTOR_FAILED = 'app/MedicineList/GET_DISTRIBUTION_DESCRIPTOR_FAILED';

export const ADD_NEW_MEDICINE_FAILED = 'app/MedicineList/ADD_NEW_MEDICINE_FAILED';

export const EDIT_MEDICINE = 'app/MedicineList/EDIT_MEDICINE';
export const EDIT_MEDICINE_FAILED = 'app/MedicineList/EDIT_MEDICINE_FAILED';

export const DELETE_MEDICINE = 'app/MedicineList/DELETE_MEDICINE';
export const DELETE_MEDICINE_SUCCESS = 'app/MedicineList/DELETE_MEDICINE_SUCCESS';
export const DELETE_MEDICINE_FAILED = 'app/MedicineList/DELETE_MEDICINE_FAILED';

/* medicine log constants */
export const GET_MEDICINE_LOG_SUCCESS = 'app/MedicineList/GET_MEDICINE_LOG_SUCCESS';
export const GET_MEDICINE_LOG_FAILED = 'app/MedicineList/GET_MEDICINE_LOG_FAILED';

export const ADD_MEDICINE_LOG = 'app/MedicineList/ADD_MEDICINE_LOG';
export const ADD_MEDICINE_LOG_FAILED = 'app/MedicineList/ADD_MEDICINE_LOG_FAILED';

export const DELETE_MEDICINE_LOG = 'app/MedicineList/DELETE_MEDICINE_LOG';
export const DELETE_MEDICINE_LOG_FAILED = 'app/MedicineList/DELETE_MEDICINE_LOG_FAILED';

/* Allergies constants */
export const GET_ALLERGIES_SUCCESS = 'app/MedicineList/GET_ALLERGIES_SUCCESS';
export const GET_ALLERGIES_FAILED = 'app/MedicineList/GET_ALLERGIES_FAILED';

export const SUBMIT_NEW_ALLERGIES_FAILED = 'app/MedicineList/SUBMIT_NEW_ALLERGIES_FAILED';

export const UPDATE_ALLERGIES_FAILED = 'app/MedicineList/UPDATE_ALLERGIES_FAILED';

/* medicine checklog constants */
export const GET_MEDICINE_CHECKLOG_SUCCESS = 'app/MedicineList/GET_MEDICINE_CHECKLOG_SUCCESS';
export const GET_MEDICINE_CHECKLOG_FAILED = 'app/MedicineList/GET_MEDICINE_CHECKLOG_FAILED';

export const ADD_MEDICINE_CHECKLOG_FAILED = 'app/MedicineList/ADD_MEDICINE_CHECKLOG_FAILED';

/* medicine constants */
export const GET_MEDICINE_HISTORY_SUCCESS = 'app/MedicineList/GET_MEDICINE_HISTORY_SUCCESS';

/* reset medicine tore */
export const RESET_MEDICINE_STORE = 'app/Careplan/RESET_MEDICINE_STORE';

export const SET_TAB_ID = 'app/MedicineList/SET_TAB_ID';
