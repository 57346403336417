/* eslint-disable import/no-import-module-exports */
import { configureStore as createStore } from '@reduxjs/toolkit';

import { CurrentUserEnum } from 'types/AuthRequired';

import api from 'utils/api';
import history from 'utils/history';
import loginSessionChannel, { CHANNEL_ACTION } from 'utils/channels/LoginSessionChannel';
import { getClientSessionToken } from 'utils/localStorage';

import createReducer from 'store/reducers';
import { RESET_STORE } from 'store/constants';
import { getPrivileges } from 'store/privileges/slice';

import { hideLoginModal, removeClientSessionTokenThunk, showLoginModal } from './auth/slice';
import { featureEnabled } from 'utils/features';
import { Features } from 'types/Features';

export type { RootState } from 'store/RootState';

const initialState = {};

// Create the store with two middlewares

const store = createStore({
  reducer: createReducer(),
  middleware: (getDefaultMiddleware) => [
    // https://redux-toolkit.js.org/api/getDefaultMiddleware#included-default-middleware
    ...getDefaultMiddleware({ serializableCheck: true }),
  ],
  preloadedState: initialState,
  devTools: process.env.NODE_ENV !== 'production',
});

export type Store = {
  asyncReducers: Record<string, unknown>;
} & typeof store;

(store as Store).asyncReducers = {}; // Async reducer registry

// clear the store when user got logged out
api.interceptors.response.use(
  (response) => response,
  (error) => {
    // if 403 we check if the user is logged in with smartcard from eResepti or social-archive
    if (error.response && error.response.status === 403) {
      const { userInfo } = store.getState().auth;
      if (userInfo.currentUserType === CurrentUserEnum.DOMACARE_WITH_SMARTCARD) {
        history.push('/cardLogout/noCard');
        store.dispatch({ type: RESET_STORE });
      }
    }
    if (error.response && error.response.status === 401) {
      store.dispatch(removeClientSessionTokenThunk());

      if (featureEnabled(Features.AuthV2)) {
        if (!history.location.pathname.includes('auth')) {
          store.dispatch(showLoginModal());
        }
      } else {
        if (history.location.pathname !== '/login' && history.location.pathname !== '/register') {
          store.dispatch(showLoginModal());
        }
      }
    }
    return Promise.reject(error);
  },
);

// eslint-disable-next-line no-promise-executor-return
const waitForMs = (ms: number) => new Promise((r) => setTimeout(r, ms));

// Main function: Listens for messages and delegates based on the AuthV2 feature toggle.
loginSessionChannel.onmessage = async (event: MessageEvent<{ type: CHANNEL_ACTION }>) => {
  if (featureEnabled(Features.AuthV2)) {
    await handleAuthV2ChannelAction(event);
  } else {
    await handleLegacyAuthChannelAction(event);
  }
};

// Function handling the AuthV2-specific logic.
async function handleAuthV2ChannelAction(event: MessageEvent<{ type: CHANNEL_ACTION }>) {
  // For AuthV2, consider any URL with 'auth' as being in the login flow.
  const inLogin = history.location.pathname.includes('auth');
  const currentToken = store.getState().auth.clientSessionToken;

  // Wait a bit to allow localStorage updates from other tabs to propagate.
  await waitForMs(650);
  const tokenFromStore = getClientSessionToken();

  switch (event.data.type) {
    case CHANNEL_ACTION.LOGIN:
      if (currentToken === tokenFromStore) {
        if (inLogin) {
          // If already in a login-related URL, just replace the history.
          history.replace('/');
        } else {
          // Otherwise, dispatch actions to update privileges and hide any modal.
          store.dispatch(getPrivileges());
          store.dispatch(hideLoginModal());
        }
      } else if (!inLogin) {
        store.dispatch(showLoginModal());
      } else {
        // Fallback: if tokens don't match but we're in login, redirect to the main page.
        window.location.reload();
      }
      break;

    case CHANNEL_ACTION.LOGOUT:
      if (!inLogin) {
        store.dispatch(showLoginModal());
      }
      break;

    default:
      console.warn('Unknown message type', event.data.type);
  }
}

// Function handling the legacy (non-AuthV2) logic.
async function handleLegacyAuthChannelAction(event: MessageEvent<{ type: CHANNEL_ACTION }>) {
  // For legacy auth, only the exact paths '/login' and '/register' are considered login.
  const inLogin = history.location.pathname === '/login' || history.location.pathname === '/register';
  const currentToken = store.getState().auth.clientSessionToken;

  // Wait for a bit to prevent any race conditions with localStorage updates.
  await waitForMs(650);
  const tokenFromStore = getClientSessionToken();

  switch (event.data.type) {
    case CHANNEL_ACTION.LOGIN:
      if (currentToken === tokenFromStore) {
        if (inLogin) {
          // For legacy auth, reload the window if already on login/register pages.
          window.location.reload();
        } else {
          // If not in the login flow, dispatch actions to update privileges and hide the login modal.
          store.dispatch(getPrivileges());
          store.dispatch(hideLoginModal());
        }
      } else if (!inLogin) {
        store.dispatch(showLoginModal());
      } else {
        // Fallback: if tokens don't match but we're in login, reload the page.
        window.location.reload();
      }
      break;

    case CHANNEL_ACTION.LOGOUT:
      if (!inLogin) {
        store.dispatch(showLoginModal());
      }
      break;

    default:
      console.warn('Unknown message type', event.data.type);
  }
}

// Make reducers hot reloadable, see https://redux.js.org/usage/configuring-your-store#hot-reloading
/* istanbul ignore next */
if (module.hot) {
  module.hot.accept('./reducers', () => {
    import('store/reducers').then((reducerModule) => {
      const createReducers = reducerModule.default;
      const nextReducers = createReducers((store as Store).asyncReducers);

      store.replaceReducer(nextReducers);
    });
  });
}

// Export custom dispatch type
export type AppDispatch = typeof store.dispatch;

export default store as Store;
