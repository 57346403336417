/*
 * This file has the types associated with login V2 when it's actually implemented
 * */

export enum LoginMethod {
  SRP,
  SAML,
  DVV_CARD,
}

export type LoginMethodSelection = { id: LoginMethod; label: string };

export type SrpPayload = {
  BHex: string;
  sHex: string;
  kHex: string;
  gDecimal: string;
  NDecimal: string;
  B: number;
  s: number;
  N: number;
  g: number;
  H: number;
  k: number;
};

export enum PhaseId {
  /**
   * Device needs to be registered
   */
  DEVICE_REGISTRATION = 'DEVICE_REGISTRATION',

  /**
   * @hidden
   */
  DEVICE_REGISTRATION_DC1_DESKTOP = 'DEVICE_REGISTRATION_DC1_DESKTOP',

  /**
   * @hidden
   */
  DEVICE_REGISTRATION_MOBILE = 'DEVICE_REGISTRATION_MOBILE',

  /**
   * Device registration via SRP credentials + SMS token
   */
  DEVICE_REGISTRATION_SRP_SMS = 'DEVICE_REGISTRATION_SRP_SMS',

  /**
   * Login possible to multiple tenants - one needs to be selected
   */
  TENANT_SELECTION = 'TENANT_SELECTION',

  /**
   * Login possible via multiple methods - one needs to be selected
   */
  LOGIN_METHOD_SELECTION = 'LOGIN_METHOD_SELECTION',

  /**
   * Login via SRP credentials
   */
  LOGIN_SRP = 'LOGIN_SRP',

  /**
   * Login via SAML SSO
   */
  LOGIN_SAML = 'LOGIN_SAML',

  /**
   * Login via SAML SSO (polling phase)
   */
  LOGIN_SAML_POLL = 'LOGIN_SAML_POLL',

  /**
   * Login via a healthcare professional ID card (Sosiaali- ja terveydenhuollon ammattikortti)
   */
  LOGIN_DVV_CARD = 'LOGIN_DVV_CARD',

  /**
   * @hidden
   * An internal Phase, never returned to clients
   */
  TENANT_INITIATED_SSO_REGISTRATION_FILTER = 'TENANT_INITIATED_SSO_REGISTRATION_FILTER',

  /**
   * Tenant initiated SSO finish
   */
  TENANT_INITIATED_SSO_FINISH = 'TENANT_INITIATED_SSO_FINISH',

  /**
   * Authentication is complete, proceed to main UI
   */
  COMPLETE = 'COMPLETE',
}

export type Tenant = {
  name: string;
  label: string;
};
