import messages from './messages';

export type ErrorCode = Record<string, { id: string; defaultMessage: string }>;

export const SPECIAL_ERROR_CASE = 'WORK_SHIFT_OUTSIDE_ACTIVE_EMPLOYEE_AGREEMENT';
export const bookingErrorCodes: ErrorCode = {
  BOOKING_PLANNING_PUBLISHING_DISABLED: messages.publishingDisabledErrorCode,
  BOOKING_PLANNING_PERIOD_VALIDATION_ERROR: messages.planningPeriodValidationErrorCode,
  BOOKING_PLANNING_PERIOD_ID_NOT_ALLOWED: messages.planningPeriodNotAllowedErrorCode,
  BOOKING_TIME_SHIFT_ASSIGNMENT_USER_HAS_NO_CREATE_PRIVILEGE: messages.noCreatePrivilege,
  BOOKING_TIME_SHIFT_ASSIGNMENT_USER_HAS_NO_UPDATE_PRIVILEGE: messages.noUpdatePrivilege,
  BOOKING_TIME_SHIFT_ASSIGNMENT_USER_HAS_NO_DELETE_PRIVILEGE: messages.noDeletePrivilege,
  BOOKING_TIME_SHIFT_ASSIGNMENT_NOT_VALID_FOR_UPDATE: messages.notValidForUpdate,
  BOOKING_TIME_SHIFT_ASSIGNMENT_BREAK_PLANNED_BEG_AFTER_PLANNED_BREAK_END: messages.plannedBegAfterPlannedBreakEnd,
  BOOKING_TIME_SHIFT_ASSIGNMENT_PLANNED_BEG_TIME_AFTER_PLANNED_END_TIME: messages.plannedBegAfterPlannedEnd,
  BOOKING_TIME_SHIFT_ASSIGNMENT_PLANNED_START_TIME_MISSING: messages.plannedStartMissing,
  BOOKING_TIME_SHIFT_ASSIGNMENT_PLANNED_END_TIME_MISSING: messages.plannedEndMissing,
  BOOKING_TIME_SHIFT_ASSIGNMENT_EMPLOYEE_ID_MISSING: messages.employeeIdMissing,
  BOOKING_TIME_SHIFT_ASSIGNMENT_EMPLOYEE_ID_NEGATIVE_OR_ZERO: messages.employeeIdNegOrZero,
  BOOKING_TIME_SHIFT_ASSIGNMENT_COMMENT_MISSING: messages.commentMissing,
  BOOKING_TIME_SHIFT_ASSIGNMENT_REALIZED_BEG_TIME_AFTER_REALIZED_END_TIME: messages.realizedBegAfterRealizedEnd,
  BOOKING_TIME_SHIFT_ASSIGNMENT_REALIZED_END_TIME_MISSING: messages.realizedEndMissing,
  BOOKING_TIME_SHIFT_ASSIGNMENT_OVERLAPPING_FOR_EMPLOYEE_NOT_ALLOWED: messages.overlappingNotAllowed,
  BOOKING_TIME_SHIFT_ASSIGNMENT_BREAK_PLANNED_TIMES_NOT_WITHIN_SHIFT: messages.breakPlannedTimeNotWithinShiftErrorCode,
  BOOKING_AUCTION_SOURCE_WAS_NULL: messages.auctionSourceNullErrorCode,
  BOOKING_AUCTION_DUPLICATE_KEY_FOUND: messages.auctionDuplicateKeyErrorCode,
  BOOKING_AUCTION_ITEMS_WERE_EMPTY: messages.auctionEmptyItemsErrorCode,
  BOOKING_ITEMS_NOT_FOUND: messages.bookingItemsMissingErrorCode,
  BOOKING_ITEM_ID_IS_REQUIRED: messages.itemIdIsMissingErrorCode,
  BOOKING_AUCTION_USERS_EMPTY: messages.auctionUserMissingErrorCode,
  BOOKING_ITEM_NEEDS_MISSING_FROM_DB: messages.itemNeedsMissingErrorCode,
  BOOKING_ITEM_CUSTOMER_ID_IS_REQUIRED: messages.itemCustomerIdIsRequiredErrorCode,
  BOOKING_AUCTION_CLOSED: messages.auctionIsClosedErrorCode,
  BOOKING_AUCTION_CALL_NOT_FOUND: messages.auctionCallNotFoundErrorCode,
  BOOKING_AUCTION_NOT_FOUND: messages.auctionCallNotFoundErrorCode,
  BOOKING_ITEM_EMPLOYEE_ID_NULL: messages.itemEmployeeIdNullErrorCode,
  BOOKING_ITEM_CUSTOMER_NOTIFY_ENTITY_MISSING: messages.itemCustomerNotifyEntityIsMissingErrorCode,
  BOOKING_ITEM_USER_NOTIFY_ENTITY_MISSING: messages.itemUserNotifyEntityIsMissingErrorCode,
  BOOKING_ITEM_HAS_ASSIGNED_EMPLOYEE: messages.itemHasAssignedEmployeeErrorCode,
  BOOKING_ITEM_ALREADY_GRADED_BY_USER: messages.itemAlreadyGradedErrorCode,
  BOOKING_ITEM_NOT_FINISHED: messages.itemNotFinishedErrorCode,
  UNDEFINED_DATABASE: messages.undefinedDatabaseErrorCode,
  UNDEFINED_USER_ID: messages.undefinedUserIdErrorCode,
  CUSTOMER_IDS_WAS_EMPTY: messages.customerIdsEmptyErrorCode,
  BOOKING_AUCTION_HAS_NO_SMS_CALL: messages.auctionNoSmsCallErrorCode,
  BOOKING_RANGE_START_IS_AFTER_RANGE_END: messages.startRangeIsAfterEndRangeErrorCode,
  BOOKING_DATE_RANGE_LIMIT_REACHED: messages.dateLimitIsReachedErrorCode,
  USER_HAS_NO_READ_ACCESS_FOR_GIVEN_TAB: messages.noAccessForTabErrorCode,
  CUSTOMER_HAVE_MULTI_BOOKING_TEMPLATE: messages.multiBookingTemplateErrorCode,
  CUSTOMER_HAS_NO_TEMPLATE_CONNECTED_TO_REPETITION: messages.noTemplateConnectedToRepetitionErrorCode,
  BOOKING_ITEM_MISSING_REPETITION: messages.itemMissingRepetitionErrorCode,
  BOOKING_TIME_SHIFT_ASSIGNMENT_DRAFT_LIST_IS_PUBLISHED: messages.draftListIsPublishedErrorCode,
  MULTI_CUSTOMER_FOUND: messages.multiCustomerFoundErrorCode,
  BOOKING_ITEM_PLANNED_TIME_NOT_PROVIDED: messages.plannedTimeNotProvidedErrorCode,
  BOOKING_ITEM_START_TIME_IS_GREATOR_THAN_END_TIME: messages.startTimeGreaterEndTimeErrorCode,
  BOOKING_ITEM_REALIZED_TIMES_WERE_PROVIDED: messages.itemRealizedWereProvidedErrorCode,
  BOOKING_ITEM_STATE_GIVEN_WAS_NOT_UNCONFIRMED: messages.itemStateNotUnconfirmedErrorCode,
  BOOKING_ITEM_CREATE_WITH_CANCELED_NOT_ALLOWED: messages.itemCreateWithCancelledNotAllowedErrorCode,
  UPDATE_CANCELED_BOOKING_ITEM_IS_NOT_ALLOWED: messages.updateCancelItemErrorCode,
  UPDATE_FINISHED_BOOKING_ITEM_IS_NOT_ALLOWED: messages.updateFinishedItemErrorCode,
  UPDATE_BOOKING_ITEM_FROM_CONFIRMED_TO_UNCONFIRMED_IS_NOT_ALLOWED: messages.fromConfirmToUnConfirmErrorCode,
  BOOKING_ITEM_UNDEFINED_STATUS: messages.undefinedStatusErrorCode,
  BOOKING_ITEM_REALIZED_END_TIME_WAS_PROVIDED: messages.realizedEndTimeProvidedErrorCode,
  BOOKING_REALIZED_START_TIME_WAS_AFTER_END_TIME: messages.realizedStartTimeAfterEndTimeErrorCode,
  BOOKING_ITEM_REALIZED_END_TIME_IS_REQUIRED: messages.realizedEndTimeRequiredErrorCode,
  BOOKING_ITEM_PROVIDED_REALIZED_STARTTIME_WAS_MODIFIED: messages.realizedStartTimeModifiedErrorCode,
  BOOKING_ITEM_REALIZED_START_TIME_IS_REQUIRED: messages.realizedStartTimeRequiredErrorCode,
  BOOKING_TIME_TAB_ID_IS_REQUIRED: messages.timeTabRequiredErrorCode,
  BOOKING_ITEM_STARTED: messages.itemStartedErrorCode,
  INVALID_PHONE_NUMBER_FORMAT: messages.invalidPhoneNumberFormatErrorCode,
  REPETITION_NDAY_MISSING: messages.repetitionNDayMissingErrorCode,
  EMPLOYEE_HAS_NO_WORK_SHIFTS: messages.employeeHasNoWorkShifts,
  DEFAULT_ERROR_CODE: messages.defaultErrorCode,
  EMPLOYMENT_AGREEMENT_NO_AGREEMENT_FOUND: messages.noAgreementFound,
  EMPLOYMENT_AGREEMENT_END_DATE_MISSING: messages.agreementEndDateMissing,
  EMPLOYMENT_AGREEMENT_MISSING_EQUALIZATION_PERIODS: messages.agreementMissingEqualizationPeriods,
  EMPLOYMENT_AGREEMENT_VALIDATION_PAYLOAD_VALIDATION_ERROR: messages.agreementValidationPayloadError,
  EMPLOYMENT_AGREEMENT_WORK_SHIFT_NOT_BELONGING_TO_ANY_PLANNING_PERIOD: messages.workShiftNotBelongToAnyPlanningPeriods,
  EMPLOYMENT_AGREEMENT_NO_WORK_TIME_STATISTICS_FOUND: messages.noWorkTimeStatistic,
  EMPLOYMENT_AGREEMENT_NO_PLANNING_PERIODS_FOUND: messages.noPlanningPeriods,
  EMPLOYMENT_AGREEMENT_CYCLE_ID_MISSING: messages.agreementCycleIdMissing,
  EMPLOYMENT_AGREEMENT_MULTIPLE_CYCLE_IDS_PRESENT: messages.agreementMultipleCycleIds,
  EMPLOYMENT_AGREEMENT_PLANNING_PERIOD_NO_EQUALIZATION_PERIOD: messages.noEqualizationPeriod,
  EMPLOYMENT_AGREEMENT_NO_INTERSECTING_PLANNING_PERIOD: messages.noIntersectingPlanningPeriod,
  EMPLOYMENT_AGREEMENT_CYCLE_ID_CALCULATION_PARAMS_MISSING: messages.agreementCycleIdMissing,
  BOOKING_PLANNING_PERIOD_LIST_NOT_FOUND: messages.noPlanningPeriodLists,
  EMPLOYMENT_AGREEMENT_USER_DOES_NOT_HAVE_AGREEMENT: messages.employeeHasNoAgreement,
  WORK_TIME_CALCULATION_PART_TIME_PERCENTAGE_MISSING: messages.fteMissing,
  BOOKING_TIME_SHIFT_ASSIGNMENT_OVERLAPPING_BREAKS_NOT_ALLOWED: messages.breaksOverlapping,
  NO_PLANNING_LISTS_AVAILABLE: messages.noPlanningLists,
  BOOKING_TIME_SHIFT_ASSIGNMENT_REPETITIONS_DO_NOT_FIT_PLANNING_LISTS: messages.noSuitablePlanningListsForReps,
  BOOKING_TIME_SHIFT_ASSIGNMENT_PRE_VALIDATION_NO_PLANNING_LISTS_FOUND: messages.noPlanningLists,
  BOOKING_TIME_SHIFT_ASSIGNMENT_PRE_VALIDATION_EMPLOYEE_DOES_NOT_HAVE_AGREEMENT: messages.employeeHasNoAgreement,
  BOOKING_TIME_SHIFT_ASSIGNMENT_PRE_VALIDATION_AGREEMENT_MISSING_CYCLE_ID: messages.agreementCycleIdMissing,
  BOOKING_TIME_SHIFT_ASSIGNMENT_PRE_VALIDATION_NO_UNPUBLISHED_LISTS_FOUND: messages.noUnPublishedPlanningLists,
  BOOKING_PLANNING_PERIOD_LIST_MISSING_AGREEMENTS: messages.noAgreementFound,
  PLANNING_DISABLED: messages.planningIsDisabled,
  EMPLOYEE_DOES_NOT_HAVE_AGREEMENT: messages.employeeHasNoAgreement,
  AGREEMENT_MISSING_CYCLE_ID: messages.agreementEndDateMissing,
  NO_PLANNING_LISTS_FOUND: messages.unpublishedPlanningLists,
  NO_UNPUBLISHED_LISTS_FOUND: messages.noUnPublishedPlanningLists,
  WORK_SHIFT_OUTSIDE_ACTIVE_EMPLOYEE_AGREEMENT: messages.workShiftOutsideAgreement,
};
