import { useEffectOnce } from 'usehooks-ts';
import { useIntl } from 'react-intl';
import { MessageDescriptor } from '@formatjs/intl';

import Spinner from 'common/Spinner';

import useReducerThunk from 'hooks/useReducerThunk';

import { State } from 'types/State';

import dvvCardLoginSlice, { getV2AuthThunk, initialState } from 'Login/DVVCardLogin/DVVCardLoginSlice';
import { FC } from 'react';
import { OverrideNavigation } from 'Login/AuthV2/types';

export const DVVCardLogin: FC<OverrideNavigation> = ({ overrideNavigation }) => {
  // we use local reducer so managing the flow will be easy (and testable)
  const [state, dispatch] = useReducerThunk(dvvCardLoginSlice, initialState);
  const { formatMessage } = useIntl();
  useEffectOnce(() => {
    dispatch(getV2AuthThunk({ overrideNavigation }));
  });

  // because message may come from browser or BE, instead of messages. We check it here
  const getMessage = (message: MessageDescriptor | string) => {
    if (typeof message === 'string') {
      return message;
    }
    return formatMessage(message);
  };

  return (
    <div className="text-center">
      {state.state === State.PENDING && <Spinner />}
      {state.message && <p className={state.error ? 'text-danger' : undefined}>{getMessage(state.message)}</p>}
    </div>
  );
};

export default DVVCardLogin;
