import api from 'utils/api';

import { WorkShiftRepetition } from 'Booking/types/TaskTimeShiftAssignment/WorkShiftRepetition';
import { TaskTimeShiftAssignment } from 'Booking/types/TaskTimeShiftAssignment/TaskTimeShiftAssignment';
import { TaskTimeShiftAssignmentType } from 'Booking/types/TaskTimeShiftAssignment/TaskTimeShiftAssignmentType';
import { WorkShiftTemplate } from 'Booking/types/TaskTimeShiftAssignment/WorkShiftTemplate';
import { InitialValues } from 'Booking/shared/WorkShiftModal/slice';
import { TaskTimeShiftAssignmentDraft } from 'Booking/types/TaskTimeShiftAssignment/TaskTimeShiftAssignmentDraft';
import { TaskTimeShiftAssignmentExtraBreak } from 'Booking/types/TaskTimeShiftAssignment/TaskTimeShiftAssignmentExtraBreak';
import { TaskTimeShiftAssignmentDraftExtraBreak } from 'Booking/types/TaskTimeShiftAssignment/TaskTimeShiftAssignmentDraftExtraBreak';
import { GetTimeShiftParams } from 'Booking/types/Parameters/GetTimeShiftParams';
import { UpdateTimeShiftParams } from 'Booking/types/Parameters/UpdateTimeShiftParams';

export const workShiftsRepetitionView = (payload: Partial<WorkShiftRepetition>) =>
  api.post<string[]>(`booking/tasktimeshiftassignment/repetitions/view`, payload);

export const createWorkShiftRepetition = (
  payload: Partial<WorkShiftRepetition>,
  params: { type: string; workShiftId: number },
) => api.post<WorkShiftRepetition>(`booking/tasktimeshiftassignment/repetitions`, payload, { params });

export const updateWorkShiftRepetition = (
  repetitionId: number,
  payload: Partial<WorkShiftRepetition>,
  params: { type: string; workShiftId: number },
) => api.patch(`booking/tasktimeshiftassignment/repetitions/${repetitionId}`, payload, { params });

export const deleteWorkShiftRepetition = (repetitionId: number, params: { type: string; excludeShifts?: number[] }) =>
  api.delete(`booking/tasktimeshiftassignment/repetitions/${repetitionId}`, { params });

export const getSingleTaskShiftAssignments = (id: number, params?: Partial<GetTimeShiftParams>) =>
  api.get<TaskTimeShiftAssignment>(`booking/tasktimeshiftassignment/${id}`, { params });

export const getTaskTimeShiftTemplate = () => api.get<WorkShiftTemplate[]>(`booking/tasktimeshifttemplates`);

export const createTimeShiftAssignment = (payload: Partial<InitialValues>, params?: { timeTabId: number }) =>
  api.post<TaskTimeShiftAssignment[]>(`booking/tasktimeshiftassignment`, [payload], { params });

export const updateSingleTimeShiftAssignment = (
  id: number,
  payload: Partial<TaskTimeShiftAssignment> | Partial<TaskTimeShiftAssignmentDraft>,
  params: UpdateTimeShiftParams,
) => api.patch<TaskTimeShiftAssignment>(`booking/tasktimeshiftassignment/${id}`, payload, { params });

export const deleteSingleTaskShiftAssignment = (id: number, params: { type: TaskTimeShiftAssignmentType }) =>
  api.delete(`booking/tasktimeshiftassignment/${id}`, { params });

export const updateTaskTimeShiftExtraBreak = (
  id: number,
  breakId: number,
  payload: TaskTimeShiftAssignmentExtraBreak | TaskTimeShiftAssignmentDraftExtraBreak,
  params: { type: TaskTimeShiftAssignmentType },
) =>
  api.patch<TaskTimeShiftAssignmentExtraBreak>(`booking/tasktimeshiftassignment/${id}/breaks/${breakId}`, payload, {
    params,
  });

export const createTaskTimeShiftExtraBreaks = (
  id: number,
  payload: Partial<TaskTimeShiftAssignmentExtraBreak>[] | Partial<TaskTimeShiftAssignmentDraftExtraBreak>[],
  params: { type: TaskTimeShiftAssignmentType },
) => api.post<TaskTimeShiftAssignmentExtraBreak>(`booking/tasktimeshiftassignment/${id}/breaks`, payload, { params });

export const deleteTaskTimeShiftExtraBreaks = (
  id: number,
  breakId: number,
  params: { type: TaskTimeShiftAssignmentType },
) => api.delete(`booking/tasktimeshiftassignment/${id}/breaks/${breakId}`, { params });

export const getWorkShifts = (
  params?: Partial<{ employeeIds: number[]; plannedStartTime: string; plannedEndTime: string }>,
) => api.get<TaskTimeShiftAssignment[]>('booking/tasktimeshiftassignment', { params });
