import { useState } from 'react';
import { useIntl } from 'react-intl';

import Button from 'common/Button';
import CloseIcon from 'common/icons/CloseIcon';

import { getCookiesConsent, setCookiesConsent } from 'utils/localStorage';

import commonTranslations from 'translations/CommonTranslations';

import messages from 'App/messages';

const COOKIE_URL = 'https://uusi.domacare.fi/evasteilmoitus';

export const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(!getCookiesConsent());
  const { formatMessage } = useIntl();

  const acceptCookies = () => {
    setCookiesConsent();
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div role="alert" aria-live="polite" className="fixed-bottom">
      <div className="bg-white border-dark border-top border-gray-200 position-relative">
        <button
          type="button"
          onClick={acceptCookies}
          className="position-absolute top-0 end-0 m-2 text-gray-500"
          aria-label="Close cookie banner"
          style={{ top: '1px', right: '1px' }} // this will transfer the button to top right corner
        >
          <CloseIcon />
        </button>
        <div className="container p-5 d-flex flex-column align-items-center justify-content-center text-center">
          <p className="text-gray-600 mb-3">
            {formatMessage(messages.cookieMessage)}{' '}
            <a href={COOKIE_URL}>{formatMessage(messages.readMoreAboutCookies)}</a>
            {'. ' /*This is intentional*/}
            {formatMessage(messages.readAlso)}
            {' ' /* and this also */}
            <a href={`${window.location.origin}/public/licenses.html`}>{formatMessage(messages.thirdPartyLicenses)}</a>
          </p>
          <Button type="button" onClick={acceptCookies}>
            {formatMessage(commonTranslations.accept)}
          </Button>
        </div>
        <div className="position-absolute bottom-0 p-3 end-0" style={{ bottom: '1px', right: '1px' }}></div>
      </div>
    </div>
  );
};

export default CookieBanner;
