import useReducerThunk from 'hooks/useReducerThunk';
import slice, { initialState, loginSamlStep1Thunk } from 'Login/SAML/slice';
import { useEffectOnce } from 'usehooks-ts';
import { useIntl } from 'react-intl';
import commonTranslations from 'translations/CommonTranslations';
import { OverrideNavigation } from 'Login/AuthV2/types';
import { FC } from 'react';

const SAML: FC<OverrideNavigation> = () => {
  const [_state, dispatch] = useReducerThunk(slice, initialState);
  const { formatMessage } = useIntl();
  useEffectOnce(() => {
    dispatch(loginSamlStep1Thunk());
  });
  return <>{formatMessage(commonTranslations.loading)}</>;
};

export default SAML;
