// eslint-disable-next-line import/no-extraneous-dependencies
import { MessageDescriptor } from '@formatjs/intl';
import { Features } from 'types/Features';
// TODO: move
import payrollMessages from 'Payroll/messages';
import messages from './featuresMessages';

const feature = (featureTitle: MessageDescriptor) => ({ featureTitle });

const appFeatures = (appFeature: Features) => {
  switch (appFeature) {
    case Features.BillingContracts:
      return feature(messages.booking);
    case Features.Booking:
      return feature(messages.booking);
    case Features.Customers:
      return feature(messages.customers);
    case Features.Calendar:
      return feature(messages.calendar);
    case Features.Duties:
      return feature(messages.duties);
    case Features.MessageCenter:
      return feature(messages.messagecenter);
    case Features.Extranet:
      return feature(messages.extranetmessages);
    case Features.RaiStats:
      return feature(messages.raiStats);
    case Features.Tasks:
      return feature(messages.tasks);
    case Features.TaskAuction:
      return feature(messages.auction);
    case Features.Settings:
      return feature(messages.settings);
    case Features.WorkPlanning:
      return feature(messages.workPlanning);
    case Features.WebView:
      return feature(messages.webView);
    case Features.FrontPage:
      return feature(messages.frontPage);
    case Features.Payroll:
      return feature(payrollMessages.payroll);
    case Features.ExportArchive:
      return feature(payrollMessages.exportArchive);
    case Features.Users:
      return feature(messages.users);
    case Features.Corrections:
      return feature(messages.corrections);
    case Features.CustomersKeys:
      return feature(messages.customersKeys);
    default:
      return feature(messages.home);
  }
};

export default appFeatures;
