import { User } from 'types/User';

import { Substance } from 'EResepti/types';

export type EffectivenessPeriod = {
  id: number;
  medicationId: number;
  periodEndMinutes: number;
  periodStartMinutes: number;
};

export type EvaluationPayload = {
  periodStartMinutes?: string;
  periodEndMinutes?: string;
};

export type EffectivenessEvaluation = {
  id: number;
  logId: number;
  periodEnd: string;
  periodStart: string;
  evaluationGrade?: string;
};

export type EffectivenessContainer = {
  effectivenessEvaluations: EffectivenessEvaluation[];
  effectivenessPeriod?: EffectivenessPeriod;
};

export type DosageField = {
  defaultTime: string;
  dosageId: number;
  label: string;
};

export type Dosage = { dosageId: number; value: string };

export type Medicine = {
  deleted: number;
  deletedAt: string;
  deleterUser: User;
  dosageDistribution: false;
  dosageText: string;
  dosages: Dosage[];
  effectivenessContainer: EffectivenessContainer;
  id: number;
  inDistributor: boolean;
  lastUpdatedAt: string;
  modifierUser: User;
  modifyReason: string;
  name: string;
  notice: string;
  purpose: string;
  startDate: string;
  endDate?: string;
  portionDistribution?: string;
  trafficRisk: boolean;
  type: string;
  vnrid: string;
  deleteReason?: string;
};

export type AdderUser = {
  allowUserToSetPassword: boolean;
  userId: string;
  userName: string;
  firstName: string;
  lastName: string;
  isAcceptSms: boolean;
};

export type MetaData = {
  modifiedAt: string;
  adderUser: AdderUser;
  modifierUser: User;
};

export type MedicineLog = {
  id: number;
  amountGiven: string;
  deleted: boolean;
  canDelete: boolean;
  dosages: Dosage[];
  givenAt: string;
  notice: string;
  givenMedication: Medicine;
  metadata: MetaData;
  effectivenessEvaluation: Evaluation;
  effectivenessContainer?: {
    effectivenessEvaluations: EffectivenessContainer;
  };
};

export type Evaluation = {
  id: number;
  logId: number;
  periodEnd: string;
  periodStart: string;
  evaluatedAt?: string;
  evaluationGrade?: string;
  info?: string;
};

export type MedicationAtc = { atcCode: string; atcCodeNameFi: string; atcCodeNameSv: string; fullName: string };

export type MedicineInfo = {
  atcCode: string;
  substitutionGroup: string;
  substitutionCode: string;
  genericSubstitution: string;
  ppbPriceEnd: string;
  beingPhasedout: boolean;
  category: number;
  categoryNameFi: string;
  childMedicine: number;
  containerNameFi: string;
  containerNameSv: string;
  datasetId: string;
  doseDistribution: number;
  fullName: string;
  humanMedicine: boolean;
  idType: string;
  marketingCompany: string;
  marketingCompanyLicense: string;
  marketingName: string;
  medicationAtc: MedicationAtc;
  narcotic: boolean;
  packageAsText: string;
  packageSize: string;
  packageUnit: string;
  packagefactor: number;
  parallelImport: string;
  pharmaceuticalForm: number;
  pharmaceuticalFormNameFi: string;
  pharmaceuticalFormNameSv: string;
  priceHilaWholesale: number;
  priceNoTax: number;
  priceTax: number;
  priceWholeSale: number;
  productNumber: string;
  psychoClass: string;
  psychoClassFull: string;
  recipeOnly: boolean;
  strengthAndUnit: string;
  trafficRisk: number;
  vatCode: number;
  vnrId: string;
  packaging: string;
  strength: number;
  substances: Substance[];
  biosimilar: number;
};

export enum TabIds {
  HISTORY = 'history',
  CURRENT_MEDICATIONS = 'currentMedications',
  UPCOMING_MEDICATION = 'upcomingMedication',
  MEDICINES_LOG = 'medicinesLog',
  ALLERGIES = 'allergies',
  MEDICATION_CHECK_LOG = 'medicationCheckLog',
  ANTI_COAGUALATION = 'antiCoagulation',
}

export type MedicineLogPayload = {
  givenAt: string;
  amountGiven: string;
  notice: string;
  givenMedication: {
    id: number;
  };
  postEffectivenessEvaluation: {
    periodStartMinutes?: string;
    periodEndMinutes?: string;
    evaluationPeriod?: EvaluationPayload;
  };
};

export enum MedicineTypes {
  CONTINUOUS = 'CONTINUOUS',
  TEMPORARY = 'TEMPORARY',
  WHEN_NEEDED = 'WHEN_NEEDED',
}

export type MedicineEffectiveness = {
  evaluationPeriod?: { effectivenessPeriod?: { periodStartMinutes: string; periodEndMinutes: string } };
};

export type MedicinePayload = {
  type: string;
  inDistributor: boolean;
  dosageDistribution: boolean;
  purpose: string;
  name: string | null;
  notice: string;
  endDate: string | null;
  dosageText: string;
  startDate: string | null;
  dosages: Dosage[];
  vnrid: string | null;
  effectivenessContainer: MedicineEffectiveness;
};

export type MedicineSuggestion = { name: string; packageForm: string; packageSize: string; vnrid: string };
export type MedicineOptions = { value: string; label: string; vnrid: string };

export type Allergies = {
  medicineAllergies: string;
  otherAllergies: string;
};

export enum DispenserState {
  DISPENSER_READY = 'DISPENSER_READY',
  DISPENSER_CHECKED = 'DISPENSER_CHECKED',
}

export type CheckLog = {
  id: number;
  checkTime: string;
  userId: number;
  userName: string;
  checkType: DispenserState;
  comments: string;
};

export type MedicineSelectOptions = {
  value: string | null;
  label: string | null;
  vnrid: string | null;
};

export type VoidFunction = () => void;
export type MedicineParameterFunction = (medicine: Medicine) => void;

export type EditMedicinePayload = {
  type: MedicineTypes;
  inDistributor: boolean;
  dosageDistribution: boolean;
  purpose: string;
  name: string;
  notice: string;
  endDate: string;
  dosageText: string;
  startDate: string;
  dosages: Dosage[];
  vnrid: number;
  modifyReason: string;
  effectivenessContainer: {
    evaluationPeriod?: EvaluationPayload;
  };
};

export type DeleteMedicinePayload = { deleted: boolean; deleteReason: string };
export type DeleteMedicineLogPayload = { deleted: boolean; reasonForDeletion: string };

export type AddMedicineLogPayload = {
  givenAt: string;
  amountGiven: string;
  notice: string;
  givenMedication: {
    id: number;
  };
  postEffectivenessEvaluation: { evaluationPeriod?: EvaluationPayload };
};

export type DispenserReadyPayload = { checkType: DispenserState.DISPENSER_READY; comments: string };
export type DispenserCheckedPayload = { checkType: DispenserState.DISPENSER_CHECKED; comments: string };

export type NewEvaluationPayload = {
  id: number;
  logId: number;
  evaluationGrade: string;
  info: string;
};

export type LoggableMedicines = {
  medicationId: number;
  formattedName: string;
};

export type DateToCheck = {
  type: string;
  inDistributor: boolean;
  dosageDistribution: boolean;
  purpose: string;
  name: string | null;
  notice: string;
  endDate?: string;
  dosageText: string;
  startDate: string | null;
  vnrid: string | null;
};

export enum LoadState {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export type Tab = {
  tabText: string;
  tabId: TabIds;
  renderContent: (tabId: TabIds) => JSX.Element;
};

export enum MedicationPrintoutType {
  ALL = 'ALL',
  TEMPORARY = 'TEMPORARY',
  CONTINUOUS = 'CONTINUOUS',
  AS_NEEDED = 'AS_NEEDED',
  DISTRIBUTION_DOSAGE = 'DISTRIBUTION_DOSAGE',
  DISPENSER = 'DISPENSER',
  DISPENSER_MEDI = 'DISPENSER_MEDI',
  DISPENSER_MINI = 'DISPENSER_MINI',
  MONITORING = 'MONITORING',
}

export enum MedicationProvision {
  ACTIVE_MEDICATION = 'ACTIVE_MEDICATION',
  UPCOMING_MEDICATION = 'UPCOMING_MEDICATION',
  REMOVED_MEDICATION = 'REMOVED_MEDICATION',
}

export type Medicines = {
  activeMedications: Medicine[];
  allergies: Allergies;
  distributionDescriptors: DosageField[];
  removedMedications: Medicine[];
  upcomingMedications: Medicine[];
};

export type MedicinePrintParams = {
  provide: MedicationProvision;
  printoutType: MedicationPrintoutType;
  startDate: string;
  endDate: string;
};
