import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { MessageDescriptor } from '@formatjs/intl';

import {
  getLoginMethodSelection,
  getSmartCardChallenge,
  getV2Auth,
  loginMethodSelection,
  postSmartCardStep2,
} from 'services/authentication/loginV2';
import { atostekCardLogin } from 'services/atostek';
import errorMessages from 'Login/DVVCardLogin/atostekApiErrorMessages';

import { State } from 'types/State';
import {
  AtostekCardLoginPayload,
  AtostekHashAlgorithm,
  AtostekLanguage,
  AtostekReaderMode,
  SmartCardStep2Payload,
} from 'types/Atostek';
import { LoginMethod } from 'types/AuthenticationV2';

import store from 'store';
import { checkAuthV2StatusThunk } from 'store/auth/slice';
import messages from 'Login/messages';
import { CARD_READER_MIN_VERSION } from 'Login/config';
import get from 'lodash/get';
import { OverrideNavigation } from 'Login/AuthV2/types';

export const DVV_CARD_LOGIN_REDUCER_NAME = 'dvvCardLogin';

export const smartCardStep2Thunk = createAsyncThunk(
  `${DVV_CARD_LOGIN_REDUCER_NAME}/postV2Login`,
  async (payload: SmartCardStep2Payload & OverrideNavigation) => {
    const response = await postSmartCardStep2(payload);
    await store.dispatch(checkAuthV2StatusThunk(payload.overrideNavigation)).unwrap();
    return response.data;
  },
);

// TODO: Temporary function to generate PEM certificate. This will be moved to BE
const generateCertificate = (str: string) =>
  `-----BEGIN CERTIFICATE-----
  ${str}
  -----END CERTIFICATE-----`;

export const postChallengeToAtostekThunk = createAsyncThunk(
  `${DVV_CARD_LOGIN_REDUCER_NAME}/postChallengeToAtostek`,
  async ({ challengeData, overrideNavigation }: { challengeData: string } & OverrideNavigation, { dispatch }) => {
    const payload: AtostekCardLoginPayload = {
      DataToSign: challengeData,
      Language: AtostekLanguage.FI,
      ReaderMode: AtostekReaderMode.NORMAL_OR_NFC,
      HashAlgorithm: AtostekHashAlgorithm.SHA256,
      MinVersion: CARD_READER_MIN_VERSION,
    };
    const response = await atostekCardLogin(payload);
    if (response.data.ErrorCode !== 0) {
      // only 0 is success, rest are errors
      throw new Error(`${response.data.ErrorCode}`);
    }
    dispatch(
      smartCardStep2Thunk({
        certificate: generateCertificate(response.data.CertificateData),
        challengeSignature: response.data.Signature,
        overrideNavigation,
      }),
    );
    return response.data;
  },
);

export const fetchSmartCardChallengeThunk = createAsyncThunk(
  `${DVV_CARD_LOGIN_REDUCER_NAME}/fetchSmartCardChallenge`,
  async (payload: OverrideNavigation, { dispatch }) => {
    await getLoginMethodSelection(); // the endpoint needs to be called even if we don't use its response
    await loginMethodSelection({
      loginMethodId: LoginMethod.DVV_CARD,
    });
    const response = await getSmartCardChallenge();
    dispatch(
      postChallengeToAtostekThunk({
        challengeData: response.data.challenge,
        overrideNavigation: payload.overrideNavigation,
      }),
    );
    return response.data;
  },
);

export const getV2AuthThunk = createAsyncThunk(
  `${DVV_CARD_LOGIN_REDUCER_NAME}/getV2Auth`,
  async (overrideNavigation: OverrideNavigation, { dispatch }) => {
    const response = await getV2Auth();
    dispatch(fetchSmartCardChallengeThunk(overrideNavigation));
    return response.data;
  },
);

export enum PHASE {
  CHALLENGE = 'CHALLENGE',
  ATOSTEK_API = 'ATOSTEK_API',
  ATOSTEK_API_RESPONSE = 'ATOSTEK_API_RESPONSE',
  LOGIN = 'LOGIN',
  POLLING = 'POLLING',
}

export type DVVCardLoginState = {
  challengeData: any;
  state: State;
  error: null | SerializedError;
  message?: string | MessageDescriptor | null;
  phase?: PHASE;
};

export const initialState: DVVCardLoginState = {
  challengeData: null,
  state: State.NOT_STARTED,
  error: null,
  message: null,
};

const dvvCardLoginSlice = createSlice({
  name: DVV_CARD_LOGIN_REDUCER_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getV2AuthThunk.pending, (draft) => {
      draft.state = State.PENDING;
      draft.error = null;
    });
    builder.addCase(getV2AuthThunk.fulfilled, (draft) => {
      draft.state = State.SUCCESS;
      draft.error = null;
    });
    builder.addCase(getV2AuthThunk.rejected, (draft, action) => {
      draft.state = State.FAILED;
      draft.error = action.error;
      draft.message = action.error.message;
    });
    builder.addCase(fetchSmartCardChallengeThunk.pending, (draft) => {
      draft.state = State.PENDING;
      draft.error = null;
      draft.phase = PHASE.CHALLENGE;
      draft.message = messages.contactingServer;
    });
    builder.addCase(fetchSmartCardChallengeThunk.fulfilled, (draft, action) => {
      draft.state = State.SUCCESS;
      draft.error = null;
      draft.challengeData = action.payload;
      draft.phase = PHASE.ATOSTEK_API;
      draft.message = messages.contactSuccessful;
    });
    builder.addCase(fetchSmartCardChallengeThunk.rejected, (draft, action) => {
      draft.state = State.FAILED;
      draft.error = action.error;
      draft.message = action.error.message;
    });
    builder.addCase(postChallengeToAtostekThunk.pending, (draft) => {
      draft.state = State.PENDING;
      draft.error = null;
      draft.phase = PHASE.ATOSTEK_API;
      draft.message = messages.connectingToCardReader;
    });
    builder.addCase(postChallengeToAtostekThunk.fulfilled, (draft, action) => {
      draft.state = State.SUCCESS;
      draft.error = null;
      draft.challengeData = action.payload;
      draft.phase = PHASE.ATOSTEK_API_RESPONSE;
      draft.message = messages.connectionToCardReaderEstablished;
    });
    builder.addCase(postChallengeToAtostekThunk.rejected, (draft, action) => {
      draft.state = State.FAILED;
      draft.error = action.error;
      draft.message = get(errorMessages, action.error.message as string, action.error.message);
    });
    builder.addCase(smartCardStep2Thunk.pending, (draft) => {
      draft.state = State.PENDING;
      draft.error = null;
      draft.phase = PHASE.LOGIN;
      draft.message = messages.connectingToServer;
    });
    builder.addCase(smartCardStep2Thunk.fulfilled, (draft) => {
      draft.state = State.SUCCESS;
      draft.error = null;
      draft.phase = PHASE.LOGIN;
      draft.message = messages.connectionToServerEstablished;
      draft.phase = PHASE.POLLING;
    });
    builder.addCase(smartCardStep2Thunk.rejected, (draft, action) => {
      draft.state = State.FAILED;
      draft.error = action.error;
      draft.message = action.error.message;
    });
  },
});

export default dvvCardLoginSlice.reducer;
