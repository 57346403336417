import React from 'react';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import { FormikDomaErrors, Input } from 'common/form/Formik';
import Button from 'common/Button';

import { loginDomauser, loginDomauserStep2 } from 'services/authentication/login';

import { extractErrorMsg } from 'utils/api/utils';

import commonTranslation from 'translations/CommonTranslations';

import messages from 'Login/messages';
import { selectUserInfo } from 'store/auth/selectors';
import { checkLoginStatusThunk, generateClientSessionTokenThunk } from 'store/auth/slice';
import { getSRPCreds } from 'Login/utils';
import { AppDispatch } from 'store';
import { getPrivileges } from 'store/privileges/slice';

export type LoginCreds = {
  userName: string;
  password: string;
};

const Login: React.FC = () => {
  const userInfo = useSelector(selectUserInfo());
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<AppDispatch>();

  const initValues: LoginCreds = { userName: '', password: '' };

  const handleSubmit = async (values: LoginCreds) => {
    const credentials = {
      identity: values.userName,
      implementation: 'THINBUS',
      db: userInfo !== undefined && userInfo.registeredDbs !== undefined ? userInfo.registeredDbs[0] : 'not found',
    };
    try {
      const response = await loginDomauser(credentials);
      const srpCreds = getSRPCreds(response.data, values);
      await loginDomauserStep2({
        astr: srpCreds.A,
        m1str: srpCreds.M1,
        implementation: 'THINBUS',
      });
      await dispatch(checkLoginStatusThunk()).unwrap();
      await dispatch(
        generateClientSessionTokenThunk({
          userId: values.userName,
          uniqueId: values.password,
        }),
      ).unwrap();
      await dispatch(getPrivileges()).unwrap();
    } catch (error) {
      toast.error(extractErrorMsg(error) || 'Login error');
    }
  };

  return (
    <Formik
      initialValues={initValues}
      onSubmit={handleSubmit}
      validate={(values) => {
        const errors: FormikDomaErrors<LoginCreds> = {};
        if (values.userName.length === 0) {
          errors.userName = commonTranslation.required;
        }
        if (values.password.length === 0) {
          errors.password = commonTranslation.required;
        }
        return errors;
      }}
    >
      {({ isValid, isSubmitting }) => (
        <Form>
          <Input autoFocus name="userName" type="text" label={formatMessage(messages.userName)} autoComplete="off" />
          <Input name="password" type="password" label={formatMessage(messages.password)} autoComplete="off" />
          <Button secondary type="submit" className="w-100 mt-2" disabled={isSubmitting || !isValid}>
            {isSubmitting ? formatMessage(messages.loggingin) : formatMessage(messages.login)}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default Login;
