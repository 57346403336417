import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { MessageDescriptor } from '@formatjs/intl';
import { postV2SrpSMS, postV2SrpSMSStep2, postV2SrpSMSStep3 } from 'services/authentication/registerV2';
import { checkAuthV2StatusThunk } from 'store/auth/slice';
import { State } from 'types/State';
import store from 'store';
import SRPClient from 'utils/thinbus-srp/thinbus-srp6client-sha256';
import { SrpPayload } from 'types/AuthenticationV2';

export const REGISTER_V2_REDUCER_NAME = 'registerV2';

export const registerV2Step1Thunk = createAsyncThunk(
  `${REGISTER_V2_REDUCER_NAME}/registerV2Step1`,
  async (payload: { username: string; phoneNumber: string; password: string }, { dispatch }) => {
    const response = await postV2SrpSMS({
      username: payload.username,
      implementation: 'THINBUS',
      phoneNumber: payload.phoneNumber,
    });
    dispatch(
      registerV2Step2Thunk({
        response: response.data,
        username: payload.username,
        password: payload.password,
        phoneNumber: payload.phoneNumber,
      }),
    );
    return response.data;
  },
);

export const registerV2Step2Thunk = createAsyncThunk(
  `${REGISTER_V2_REDUCER_NAME}/registerV2Step2`,
  async (payload: { response: SrpPayload; username: string; password: string; phoneNumber: string }) => {
    const srpcli = new SRPClient({
      N_base10: payload.response.NDecimal,
      g_base10: payload.response.gDecimal,
      k_base16: payload.response.kHex,
    });
    (srpcli as any).step1(payload.username, payload.password);
    const srpCreds: { A: string; M1: string } = (srpcli as any).step2(payload.response.sHex, payload.response.BHex);
    const step2Payload = {
      AHex: srpCreds.A,
      M1Hex: srpCreds.M1,
    };
    await postV2SrpSMSStep2(step2Payload);
    return { username: payload.username, password: payload.password, phoneNumber: payload.phoneNumber };
  },
);

export const registerV2Step3Thunk = createAsyncThunk(
  `${REGISTER_V2_REDUCER_NAME}/registerV2Step3`,
  async (payload: { token: string; phoneNumber: string }) => {
    const response = await postV2SrpSMSStep3({
      token: payload.token,
    });
    store.dispatch(checkAuthV2StatusThunk());
    return response.data;
  },
);

export enum RegisterV2Step {
  DEVICE_REGISTRATION,
  SMS_VERIFICATION,
}

export type RegisterV2State = {
  state: State;
  error: null | SerializedError;
  message?: string | MessageDescriptor | null;
  attempts: number;
  step: RegisterV2Step;
  phoneNumber: string;
};

export const initialState: RegisterV2State = {
  state: State.NOT_STARTED,
  error: null,
  message: null,
  attempts: 0,
  step: RegisterV2Step.DEVICE_REGISTRATION,
  phoneNumber: '',
};

const registerV2Slice = createSlice({
  name: REGISTER_V2_REDUCER_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(registerV2Step1Thunk.pending, (draft, action) => {
      draft.state = State.PENDING;
      draft.error = null;
      draft.phoneNumber = action.meta.arg.phoneNumber;
    });
    builder.addCase(registerV2Step1Thunk.fulfilled, (draft) => {
      draft.state = State.SUCCESS;
      draft.error = null;
    });
    builder.addCase(registerV2Step1Thunk.rejected, (draft, action) => {
      draft.state = State.FAILED;
      draft.error = action.error;
      draft.message = action.error.message;
    });
    builder.addCase(registerV2Step2Thunk.pending, (draft) => {
      draft.state = State.PENDING;
      draft.error = null;
    });
    builder.addCase(registerV2Step2Thunk.fulfilled, (draft) => {
      draft.state = State.SUCCESS;
      draft.error = null;
      draft.step = RegisterV2Step.SMS_VERIFICATION;
    });
    builder.addCase(registerV2Step2Thunk.rejected, (draft, action) => {
      draft.state = State.FAILED;
      draft.error = action.error;
      draft.message = action.error.message;
    });
    builder.addCase(registerV2Step3Thunk.pending, (draft) => {
      draft.state = State.PENDING;
      draft.error = null;
    });
    builder.addCase(registerV2Step3Thunk.fulfilled, (draft) => {
      draft.state = State.SUCCESS;
      draft.error = null;
    });
    builder.addCase(registerV2Step3Thunk.rejected, (draft, action) => {
      draft.state = State.FAILED;
      draft.error = action.error;
      draft.message = action.error.message;
      draft.attempts = draft.attempts + 1;
    });
  },
});

export default registerV2Slice.reducer;
