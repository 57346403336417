import { defineMessages } from 'react-intl';

export default defineMessages({
  actuary: {
    id: 'app.components.medicationList.actuary',
    defaultMessage: 'Actuary',
  },
  addNewMedicine: {
    id: 'app.components.medicationList.addNewMedicine',
    defaultMessage: 'Add new medication',
  },
  allergies: {
    id: 'app.components.medicationList.allergies',
    defaultMessage: 'Allergies',
  },
  cancel: {
    id: 'app.components.medicationList.cancel',
    defaultMessage: 'Cancel',
  },
  cards: {
    id: 'app.components.medicationList.cards',
    defaultMessage: 'Cards',
  },
  close: {
    id: 'app.components.medicationList.close',
    defaultMessage: 'Close',
  },
  continuous: {
    id: 'app.components.medicationList.continuous',
    defaultMessage: 'Continuous',
  },
  currentMedication: {
    id: 'app.components.medicationList.currentMedication',
    defaultMessage: 'Current medication',
  },
  date: {
    id: 'app.components.medicationList.date',
    defaultMessage: 'Date',
  },
  dateAndTime: {
    id: 'app.components.medicationList.dateAndTime',
    defaultMessage: 'Date and time',
  },
  distribution: {
    id: 'app.components.medicationList.distribution',
    defaultMessage: 'Distribution',
  },
  distributionTime: {
    id: 'app.components.medicationList.distributionTime',
    defaultMessage: 'Distribution time',
  },
  dispenserChecked: {
    id: 'app.components.medicationList.dispenserChecked',
    defaultMessage: 'Dispenser checked',
  },
  dispenserReady: {
    id: 'app.components.medicationList.dispenserReady',
    defaultMessage: 'Dispenser ready',
  },
  dispenser: {
    id: 'app.components.medicationList.dispenser',
    defaultMessage: 'Dispenser',
  },
  dosage: {
    id: 'app.components.medicationList.dosage',
    defaultMessage: 'Dosage',
  },
  nameOrAttribute: {
    id: 'app.components.medicationList.nameOrAttribute',
    defaultMessage: 'Eg. name or attribute',
  },
  endDate: {
    id: 'app.components.medicationList.endDate',
    defaultMessage: 'End Date',
  },
  editMedicine: {
    id: 'app.components.medicationList.editMedicine',
    defaultMessage: 'Edit medication',
  },
  hiddenColumns: {
    id: 'app.components.medicationList.hiddenColumns',
    defaultMessage: 'Hidden columns',
  },
  history: {
    id: 'app.components.medicationList.history',
    defaultMessage: 'History',
  },
  lastEdited: {
    id: 'app.components.medicationList.lastEdited',
    defaultMessage: 'Last edited',
  },
  leaveOpen: {
    id: 'app.components.medicationList.leaveOpen',
    defaultMessage: 'Leave open',
  },
  medicationAllergies: {
    id: 'app.components.medicationList.medicationAllergies',
    defaultMessage: 'Medicine allergies',
  },
  medicationAberration: {
    id: 'app.components.medicationList.medicationAberration',
    defaultMessage: 'Medication aberration',
  },
  medicationCheckLog: {
    id: 'app.components.medicationList.medicationCheckLog',
    defaultMessage: 'Medication check log',
  },
  medicationList: {
    id: 'app.components.medicationList.medicationList',
    defaultMessage: 'Medication List',
  },
  medicationMonitoring: {
    id: 'app.components.medicationList.medicationMonitoring',
    defaultMessage: 'Medication monitoring',
  },
  medicine: {
    id: 'app.components.medicationList.medicine',
    defaultMessage: 'Medicine',
  },
  medicineName: {
    id: 'app.components.medicationList.medicineName',
    defaultMessage: 'Medicine name',
  },
  medicineInfo: {
    id: 'app.components.medicationList.medicineInfo',
    defaultMessage: 'Medicine info',
  },
  name: {
    id: 'app.components.medicationList.name',
    defaultMessage: 'Name',
  },
  necessary: {
    id: 'app.components.medicationList.necessary',
    defaultMessage: 'When needed',
  },
  nextTime: {
    id: 'app.components.medicationList.nextTime',
    defaultMessage: 'Next time',
  },
  no: {
    id: 'app.components.medicationList.no',
    defaultMessage: 'No',
  },
  notes: {
    id: 'app.components.medicationList.notes',
    defaultMessage: 'notes',
  },
  reasonForChange: {
    id: 'app.components.medicationList.reasonForChange',
    defaultMessage: 'Reason for change',
  },
  or: {
    id: 'app.components.medicationList.or',
    defaultMessage: 'Or',
  },
  otherAllergies: {
    id: 'app.components.medicationList.otherAllergies',
    defaultMessage: 'Other allergies',
  },
  pause: {
    id: 'app.components.medicationList.pause',
    defaultMessage: 'Pause',
  },
  paused: {
    id: 'app.components.medicationList.paused',
    defaultMessage: 'PAUSED',
  },
  pausedMedication: {
    id: 'app.components.medicationList.pausedMedication',
    defaultMessage: 'Pause medication',
  },
  pillDispenser: {
    id: 'app.components.medicationList.pillDispenser',
    defaultMessage: 'Pill dispenser',
  },
  portionDistribution: {
    id: 'app.components.medicationList.portionDistribution',
    defaultMessage: 'Portion distribution',
  },
  print: {
    id: 'app.components.medicationList.print',
    defaultMessage: 'Print',
  },
  purpose: {
    id: 'app.components.medicationList.purpose',
    defaultMessage: 'Purpose',
  },
  reason: {
    id: 'app.components.medicationList.reason',
    defaultMessage: 'Reason',
  },
  save: {
    id: 'app.components.medicationList.save',
    defaultMessage: 'Save',
  },
  search: {
    id: 'app.components.medicationList.search',
    defaultMessage: 'Search',
  },
  searchAndOptions: {
    id: 'app.components.medicationList.searchAndOptions',
    defaultMessage: 'Search and options',
  },
  showTableOrganizer: {
    id: 'app.components.medicationList.showTableOrganizer',
    defaultMessage: 'Show table organizer',
  },
  sortBy: {
    id: 'app.components.medicationList.sortBy',
    defaultMessage: 'Sort by',
  },
  status: {
    id: 'app.components.medicationList.status',
    defaultMessage: 'Status',
  },
  startDate: {
    id: 'app.components.medicationList.startDate',
    defaultMessage: 'Start Date',
  },
  table: {
    id: 'app.components.medicationList.table',
    defaultMessage: 'Table',
  },
  temporary: {
    id: 'app.components.medicationList.temporary',
    defaultMessage: 'Temporary',
  },
  timesPerDay: {
    id: 'app.components.medicationList.timesPerDay',
    defaultMessage: 'Times per day',
  },
  today: {
    id: 'app.components.medicationList.today',
    defaultMessage: 'Today',
  },
  type: {
    id: 'app.components.medicationList.type',
    defaultMessage: 'Type',
  },
  upcomingMedication: {
    id: 'app.components.medicationList.upcomingMedication',
    defaultMessage: 'Upcoming medication',
  },
  user: {
    id: 'app.components.medicationList.user',
    defaultMessage: 'User',
  },
  view: {
    id: 'app.components.medicationList.view',
    defaultMessage: 'View',
  },
  yes: {
    id: 'app.components.medicationList.yes',
    defaultMessage: 'Yes',
  },
  significantUpdate: {
    id: 'app.components.medicationList.significantUpdate',
    defaultMessage: 'Save to history',
  },
  deleteMedicine: {
    id: 'app.components.medicationList.deleteMedicine',
    defaultMessage: 'Delete medicine',
  },
  deleteMedicineConfirmation: {
    id: 'app.components.medicationList.deleteMedicineConfirmation',
    defaultMessage: 'Are you sure you want to remove {medicineName}?',
  },
  removeLogEntry: {
    id: 'app.components.medicationList.removeLogEntry',
    defaultMessage: 'Remove entry from log',
  },
  removeLogEntryConfirmation: {
    id: 'app.components.medicationList.removeLogEntryConfirmation',
    defaultMessage: 'Please write your reason for deleting the entry',
  },
  addNewMedicineLog: {
    id: 'app.components.medicationList.addNewMedicineLog',
    defaultMessage: 'Add medicine log',
  },
  lastModifier: {
    id: 'app.components.medicationList.lastModifier',
    defaultMessage: 'Last modifier',
  },
  lastModifyTime: {
    id: 'app.components.medicationList.lastModifyTime',
    defaultMessage: 'Last modified time',
  },
  effectiveness: {
    id: 'app.components.medicationList.effectiveness',
    defaultMessage: 'Effectiveness',
  },
  evaluationQuestion: {
    id: 'app.components.medicationList.evaluationQuestion',
    defaultMessage: 'Did the medicine work as intended?',
  },
  effective: {
    id: 'app.components.medicationList.effective',
    defaultMessage: 'Effective',
  },
  medicineMonitoring: {
    id: 'app.components.medicationList.medicineMonitoring',
    defaultMessage: 'Medicine monitoring',
  },
  monitoringTimeMinutes: {
    id: 'app.components.medicationList.monitoringTimeMinutes',
    defaultMessage: 'Monitoring Time (minutes)',
  },
  evaluateNow: {
    id: 'app.components.medicationList.evaluateNow',
    defaultMessage: 'Evaluate now',
  },
  noEvaluationPeriod: {
    id: 'app.components.medicationList.noEvaluationPeriod',
    defaultMessage: 'No evaluation period',
  },
  pvm: {
    id: 'app.components.medicationList.pvm',
    defaultMessage: 'Pvm',
  },
  extraMedicineInfo: {
    id: 'app.components.medicationList.extraMedicineInfo',
    defaultMessage: 'Extra medicine info',
  },
  medicineAdded: {
    id: 'app.components.medicationList.medicineAdded',
    defaultMessage: 'Medicine added',
  },
  medicineUpdated: {
    id: 'app.components.medicationList.medicineUpdated',
    defaultMessage: 'Medicine updated',
  },
  medicineRemoved: {
    id: 'app.components.medicationList.medicineRemoved',
    defaultMessage: 'Medicine removed',
  },
  medicineLogAdded: {
    id: 'app.components.medicationList.medicineLogAdded',
    defaultMessage: 'Medicine log added',
  },
  medicineLogDeleted: {
    id: 'app.components.medicationList.medicineLogDeleted',
    defaultMessage: 'Medicine log deleted',
  },
  allergiesAdded: {
    id: 'app.components.medicationList.allergiesAdded',
    defaultMessage: 'Allergies added',
  },
  medicineCheckLogAdded: {
    id: 'app.components.medicationList.medicineCheckLogAdded',
    defaultMessage: 'Medicine logged',
  },
  noMedicineLog: {
    id: 'app.components.medicationList.noMedicineLog',
    defaultMessage: 'No medicine logs found',
  },
  active: {
    id: 'app.components.medicationList.active',
    defaultMessage: 'Active',
  },
  medicineIsEffective: {
    id: 'app.components.medicationList.medicineIsEffective',
    defaultMessage: 'Medicine is effective',
  },
  medicineIsNotEffective: {
    id: 'app.components.medicationList.medicineIsNotEffective',
    defaultMessage: 'Medicine is not effective',
  },
  confirm: {
    id: 'app.components.medicationList.confirm',
    defaultMessage: 'Medicine is not confirm',
  },
  additionalInfo: {
    id: 'app.components.medicationList.additionalInfo',
    defaultMessage: 'Additional info',
  },
  notesPlaceholder: {
    id: 'app.components.medicationList.notesPlaceholder',
    defaultMessage:
      "Always record your observations. For example 'OK'. If you notice a deviation, record the measures taken here.",
  },
  startLogDate: {
    id: 'app.components.medicationList.startLogDate',
    defaultMessage: 'Administration date',
  },
  startLogTime: {
    id: 'app.components.medicationList.startLogTime',
    defaultMessage: 'Administration time',
  },
  addLogEntry: {
    id: 'app.components.medicationList.addLogEntry',
    defaultMessage: 'Add log entry',
  },
  vnr: {
    id: 'app.components.medicationList.vnr',
    defaultMessage: 'VNR',
  },
  packaging: {
    id: 'app.components.medicationList.packaging',
    defaultMessage: 'Packaging',
  },
  beingPhasedOut: {
    id: 'app.components.medicationList.beingPhasedOut',
    defaultMessage: 'Being phased out',
  },
  categoryName: {
    id: 'app.components.medicationList.categoryName',
    defaultMessage: 'Category name',
  },
  fullName: {
    id: 'app.components.medicationList.fullName',
    defaultMessage: 'Full name',
  },
  humanMedicine: {
    id: 'app.components.medicationList.humanMedicine',
    defaultMessage: 'Human medicine',
  },
  marketingCompany: {
    id: 'app.components.medicationList.marketingCompany',
    defaultMessage: 'Marketing company',
  },
  marketingName: {
    id: 'app.components.medicationList.marketingName',
    defaultMessage: 'Marketing name',
  },
  narcotic: {
    id: 'app.components.medicationList.narcotic',
    defaultMessage: 'Narcotic',
  },
  packageFactor: {
    id: 'app.components.medicationList.packageFactor',
    defaultMessage: 'Package factor',
  },
  packageSize: {
    id: 'app.components.medicationList.packageSize',
    defaultMessage: 'Package size',
  },
  packageUnit: {
    id: 'app.components.medicationList.packageUnit',
    defaultMessage: 'Package unit',
  },
  parallelImport: {
    id: 'app.components.medicationList.parallelImport',
    defaultMessage: 'Parallel import',
  },
  priceNoTax: {
    id: 'app.components.medicationList.priceNoTax',
    defaultMessage: 'Price without tax',
  },
  priceWithTax: {
    id: 'app.components.medicationList.priceWithTax',
    defaultMessage: 'Price with tax',
  },
  wholeSalePrice: {
    id: 'app.components.medicationList.wholeSalePrice',
    defaultMessage: 'Wholesale price',
  },
  recipeOnly: {
    id: 'app.components.medicationList.recipeOnly',
    defaultMessage: 'Recipe only',
  },
  strength: {
    id: 'app.components.medicationList.strength',
    defaultMessage: 'Strength',
  },
  strengthAndUnit: {
    id: 'app.components.medicationList.strengthAndUnit',
    defaultMessage: 'Strength and unit',
  },
  trafficRisk: {
    id: 'app.components.medicationList.trafficRisk',
    defaultMessage: 'Traffic risk',
  },
  containerName: {
    id: 'app.components.medicationList.containerName',
    defaultMessage: 'Container name',
  },
  atcCode: {
    id: 'app.components.medicationList.atcCode',
    defaultMessage: 'ATC code',
  },
  atcCodeName: {
    id: 'app.components.medicationList.atcCodeName',
    defaultMessage: 'Name of the pharmaceutical substance',
  },
  substances: {
    id: 'app.components.medicationList.substances',
    defaultMessage: 'Substances',
  },
  atcName: {
    id: 'app.components.medicationList.atcName',
    defaultMessage: 'ATC name',
  },
  amountValue: {
    id: 'app.components.medicationList.amountValue',
    defaultMessage: 'Amount value',
  },
  amountUnit: {
    id: 'app.components.medicationList.amountUnit',
    defaultMessage: 'Amount unit',
  },
  amountText: {
    id: 'app.components.medicationList.amountText',
    defaultMessage: 'Amount text',
  },
  unCodedName: {
    id: 'app.components.medicationList.unCodedName',
    defaultMessage: 'Uncoded name',
  },
  primarySubstance: {
    id: 'app.components.medicationList.primarySubstance',
    defaultMessage: 'Primary substance',
  },
  bioSimilar: {
    id: 'app.components.medicationList.bioSimilar',
    defaultMessage: 'Biosimilar',
  },
  administrationRoutes: {
    id: 'app.components.medicationList.administrationRoutes',
    defaultMessage: 'Administration routes',
  },
  dosageUnit: {
    id: 'app.components.medicationList.dosageUnit',
    defaultMessage: 'Dosage unit',
  },
  compensationClasses: {
    id: 'app.components.medicationList.compensationClasses',
    defaultMessage: 'Compensation classes',
  },
  medicationATC: {
    id: 'app.components.medicationList.medicationATC',
    defaultMessage: 'Medication ATC',
  },
  antiCoagulation: {
    id: 'app.components.medicationList.antiCoagulation',
    defaultMessage: 'Anticoagulation',
  },
  monitoringMethod: {
    id: 'app.components.medicationList.monitoringMethod',
    defaultMessage: 'Monitoring method',
  },
  nextControlDate: {
    id: 'app.components.medicationList.nextControlDate',
    defaultMessage: 'Next control date',
  },
  printMedicineMessage: {
    id: 'app.components.medicationList.printMedicineMessage',
    defaultMessage: 'Please select the duration to print',
  },
});
