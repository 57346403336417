import { ThunkDispatch, ThunkAction, Reducer } from '@reduxjs/toolkit';
import { ReducerState, ReducerAction, useReducer, useCallback } from 'react';

function useReducerThunk<R extends Reducer, I>(
  reducer: R,
  initialState: I & ReducerState<R>,
): [ReducerState<R>, ThunkDispatch<ReducerState<R>, unknown, ReducerAction<R>>] {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getState = () => state;

  const customDispatch: ThunkDispatch<ReducerState<R>, unknown, ReducerAction<R>> = useCallback(
    (action: ThunkAction<void, ReducerState<R>, unknown, ReducerAction<R>>) => {
      if (typeof action === 'function') {
        return action(customDispatch, getState, undefined);
      }
      return dispatch(action);
    },
    // We don't want to update getState
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return [state, customDispatch];
}

export default useReducerThunk;
