import {
  Allergies,
  CheckLog,
  DosageField,
  Medicine,
  MedicineLog,
  Medicines,
  TabIds,
} from 'sharedComponents/MedicineList/types';

import {
  GET_MEDICINE_LIST_SUCCESS,
  GET_DISTRIBUTION_DESCRIPTOR_SUCCESS,
  EDIT_MEDICINE,
  DELETE_MEDICINE,
  GET_MEDICINE_LOG_SUCCESS,
  ADD_MEDICINE_LOG,
  DELETE_MEDICINE_LOG,
  GET_ALLERGIES_SUCCESS,
  GET_MEDICINE_CHECKLOG_SUCCESS,
  GET_MEDICINE_HISTORY_SUCCESS,
  RESET_MEDICINE_STORE,
  SET_TAB_ID,
} from './constants';

export type MedicineState = {
  medicines: Medicines | null;
  medicineLog: MedicineLog[];
  medicineCheckLog: CheckLog[] | null;
  medicineHistory: Medicine[];
  dosageFields: DosageField[];
  allergies: Allergies | null;
  tabId: TabIds;
};

const initialState: MedicineState = {
  medicines: null,
  medicineLog: [],
  medicineCheckLog: null,
  medicineHistory: [],
  dosageFields: [],
  allergies: null,
  tabId: TabIds.CURRENT_MEDICATIONS,
};

function medicineListReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_MEDICINE_LIST_SUCCESS:
      // When there is no medicine, we get an empty string
      return { ...state, medicines: action.medicineList || [] };
    case GET_DISTRIBUTION_DESCRIPTOR_SUCCESS:
      return { ...state, dosageFields: action.dosageFields };

    case EDIT_MEDICINE:
      return { ...state, medicines: null };

    case DELETE_MEDICINE:
      return { ...state, medicines: null };

    case GET_MEDICINE_LOG_SUCCESS:
      return { ...state, medicineLog: action.medicineLog };

    case ADD_MEDICINE_LOG:
      return { ...state, medicineLog: [] };

    case DELETE_MEDICINE_LOG:
      return { ...state, medicineLog: [] };

    case GET_MEDICINE_CHECKLOG_SUCCESS:
      return { ...state, medicineCheckLog: action.medicineCheckLog };

    case GET_ALLERGIES_SUCCESS:
      return { ...state, allergies: action.allergies };

    case GET_MEDICINE_HISTORY_SUCCESS:
      return { ...state, medicineHistory: action.medicineHistory };

    case RESET_MEDICINE_STORE:
      return { ...state, medicines: null, medicineLog: [], medicineCheckLog: null, medicineHistory: null };

    case SET_TAB_ID:
      return { ...state, tabId: action.tabId };

    default:
      return state;
  }
}

export default medicineListReducer;
