import { Form, Formik } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';

import { FormikDomaErrors, Input } from 'common/form/Formik';
import Button from 'common/Button';

import messages from 'Login/messages';

export type RegisterPhoneFields = {
  phoneNumber: string;
  userName: string;
  password: string;
};

type Props = {
  onSubmit: (values: RegisterPhoneFields) => Promise<void>;
  loading?: boolean;
};

const RegisterByPhone: React.FC<Props> = ({ onSubmit, loading }) => {
  const { formatMessage } = useIntl();
  const initValues: RegisterPhoneFields = {
    phoneNumber: '',
    userName: '',
    password: '',
  };

  const handleSubmit = (values: RegisterPhoneFields) => {
    // This removes the empty spaces and characters other than numbers from the phone number
    const formattedPhoneNumber = values.phoneNumber.replace(/[^\d+]/g, '');
    return onSubmit({
      ...values,
      phoneNumber: formattedPhoneNumber,
    });
  };

  return (
    <Formik
      initialValues={initValues}
      onSubmit={handleSubmit}
      validate={(values) => {
        const errors: FormikDomaErrors<RegisterPhoneFields> = {};

        if (values.phoneNumber && values.phoneNumber.length <= 9) {
          errors.phoneNumber = formatMessage(messages.shortPhoneNumber);
        }
      }}
    >
      {({ isValid, isSubmitting }) => (
        <Form>
          <Input
            name="phoneNumber"
            type="tel"
            label={formatMessage(messages.phoneNumber)}
            required
            autoComplete="off"
            autoFocus
          />
          <Input name="userName" type="text" label={formatMessage(messages.userName)} required autoComplete="off" />
          <Input name="password" type="password" label={formatMessage(messages.password)} required autoComplete="off" />
          <Button loading={loading} type="submit" className="w-100 mt-2" secondary disabled={!isValid || isSubmitting}>
            {formatMessage(messages.send)}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default RegisterByPhone;
