import React from 'react';
import { Form, Formik } from 'formik';
import { useIntl } from 'react-intl';

import { FormikDomaErrors, Input } from 'common/form/Formik';
import Button from 'common/Button';

import { loginV2Step1Thunk } from './slice';
import messages from 'Login/messages';
import commonTranslation from 'translations/CommonTranslations';
import useReducerThunk from 'hooks/useReducerThunk';
import loginV2Reducer, { initialState } from './slice';
import { State } from 'types/State';
import { featureEnabled } from 'utils/features';
import { Features } from 'types/Features';
import history from 'utils/history';
import { PhaseId } from 'types/AuthenticationV2';
import { OverrideNavigation } from 'Login/AuthV2/types';

export type LoginV2Creds = {
  username: string;
  password: string;
};

const initValues: LoginV2Creds = { username: '', password: '' };

const LoginV2: React.FC<OverrideNavigation> = () => {
  const { formatMessage } = useIntl();
  const [{ state, error }, dispatch] = useReducerThunk(loginV2Reducer, initialState);

  const handleSubmit = async (values: LoginV2Creds) => {
    dispatch(loginV2Step1Thunk(values));
  };

  return (
    <Formik
      initialValues={initValues}
      onSubmit={handleSubmit}
      validateOnMount
      validate={(values) => {
        const errors: FormikDomaErrors<LoginV2Creds> = {};
        if (values.username.length === 0) {
          errors.username = commonTranslation.required;
        }
        if (values.password.length === 0) {
          errors.password = commonTranslation.required;
        }
        return errors;
      }}
    >
      {({ isValid, isSubmitting }) => (
        <Form>
          <Input autoFocus name="username" type="text" label={formatMessage(messages.userName)} autoComplete="off" />
          <Input name="password" type="password" label={formatMessage(messages.password)} autoComplete="off" />
          <Button
            loading={state === State.PENDING}
            secondary
            type="submit"
            className="w-100 mt-2"
            disabled={isSubmitting || !isValid}
          >
            {isSubmitting ? formatMessage(messages.loggingin) : formatMessage(messages.login)}
          </Button>
          {error && <p className="text-danger text-center pt-3">{error.message}</p>}
          {featureEnabled(Features.AuthV2) && (
            <div className="text-center pt-3">
              {formatMessage(commonTranslation.or)}
              <Button
                success
                className="w-100 mt-2"
                onClick={() => {
                  history.push(`${PhaseId.LOGIN_METHOD_SELECTION}`);
                }}
              >
                {formatMessage(messages.loginMethodSelection)}
              </Button>
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default LoginV2;
