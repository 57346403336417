import { PhaseId } from 'types/AuthenticationV2';
import LoginV2 from 'Login/components/Login/LoginV2';
import RegisterV2 from 'Login/Register/RegisterV2';
import LoginWrapper from 'Login/components/LoginWrapper/LoginWrapper';
import DVVCardLogin from 'Login/DVVCardLogin/DVVCardLogin';
import TenantSelection from 'Login/TenantSelection/TenantSelection';
import SAML from 'Login/SAML/SAML';
import LoginMethodSelection from 'Login/components/Login/LoginMethodSelection';
import { FC } from 'react';
import Button from 'common/Button';
import commonTranslations from 'translations/CommonTranslations';
import history from 'utils/history';
import { useIntl } from 'react-intl';

type AuthV2Props = {
  params: { feature: PhaseId };
  inModal?: boolean;
};

const LoginWrapperWithBackButton: FC = ({ children }) => {
  const { formatMessage } = useIntl();
  return (
    <LoginWrapper>
      <div className="d-flex flex-column">
        {children}
        <Button success onClick={() => history.push(`${PhaseId.LOGIN_METHOD_SELECTION}`)}>
          {formatMessage(commonTranslations.back)}
        </Button>
      </div>
    </LoginWrapper>
  );
};

/*
 * inModal prop is used to determine if the component is being rendered in login modal or not.
 * When it's in modal, all navigation is overridden and the login flow is controlled by the modal, instead of enums from the backend
 * TODO: Some components may be removed, as they probably cannot be used in this context
 * */
const AuthV2: FC<AuthV2Props> = ({ params, inModal }) => {
  // This is for login modal, and therefore all navigations are overridden when it's on
  if (inModal) {
    switch (params.feature) {
      case PhaseId.LOGIN_SRP:
        return <LoginV2 overrideNavigation />;
      case PhaseId.DEVICE_REGISTRATION_SRP_SMS:
        return <RegisterV2 overrideNavigation />;
      case PhaseId.LOGIN_METHOD_SELECTION:
        return <LoginMethodSelection overrideNavigation />;
      case PhaseId.LOGIN_DVV_CARD:
        return <DVVCardLogin overrideNavigation />;
      case PhaseId.TENANT_SELECTION:
        return <TenantSelection overrideNavigation />;
      case PhaseId.LOGIN_SAML:
        return <SAML overrideNavigation />;
      default:
        return <LoginV2 overrideNavigation />;
    }
  }

  switch (params.feature) {
    case PhaseId.LOGIN_SRP:
      return (
        <LoginWrapper>
          <LoginV2 />
        </LoginWrapper>
      );
    case PhaseId.DEVICE_REGISTRATION_SRP_SMS:
      return (
        <LoginWrapper>
          <RegisterV2 />
        </LoginWrapper>
      );
    case PhaseId.LOGIN_METHOD_SELECTION:
      return (
        <LoginWrapper>
          <LoginMethodSelection />
        </LoginWrapper>
      );
    case PhaseId.LOGIN_DVV_CARD:
      return (
        <LoginWrapperWithBackButton>
          <DVVCardLogin />
        </LoginWrapperWithBackButton>
      );
    case PhaseId.TENANT_SELECTION:
      return (
        <LoginWrapper>
          <TenantSelection />
        </LoginWrapper>
      );
    case PhaseId.LOGIN_SAML:
      return (
        <LoginWrapperWithBackButton>
          <SAML />
        </LoginWrapperWithBackButton>
      );
    default:
      return (
        <LoginWrapper>
          <LoginV2 />
        </LoginWrapper>
      );
  }
};

export default AuthV2;
