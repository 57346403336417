// src/Login/SAML/slice.ts
import { createSlice, createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { postLoginV2Saml, postLoginV2SamlStep2, postLoginV2SamlStep3 } from 'services/authentication/SAMLV2';
import { State } from 'types/State';

export type SAMLState = {
  state: State;
  error: SerializedError | null;
  step1Url: string | null;
};

export const initialState: SAMLState = {
  state: State.NOT_STARTED,
  error: null,
  step1Url: null,
};

export const loginSamlStep1Thunk = createAsyncThunk('saml/loginStep1', async (_payload, { rejectWithValue }) => {
  try {
    const response = await postLoginV2Saml();
    window.open(response.data.url, '_blank', 'noopener,noreferrer');
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const loginSamlStep2Thunk = createAsyncThunk(
  'saml/loginStep2',
  async (payload: { SAMLResponse: string }, { rejectWithValue }) => {
    try {
      const response = await postLoginV2SamlStep2(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const loginSamlStep3Thunk = createAsyncThunk(
  'saml/loginStep3',
  async (payload: { SAMLResponse: string }, { rejectWithValue }) => {
    try {
      const response = await postLoginV2SamlStep3(payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

const samlSlice = createSlice({
  name: 'saml',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginSamlStep1Thunk.pending, (state) => {
        state.state = State.PENDING;
        state.error = null;
      })
      .addCase(loginSamlStep1Thunk.fulfilled, (state, action) => {
        state.state = State.SUCCESS;
        state.step1Url = action.payload.url;
      })
      .addCase(loginSamlStep1Thunk.rejected, (state, action) => {
        state.state = State.FAILED;
        state.error = action.payload as SerializedError;
      })
      .addCase(loginSamlStep2Thunk.pending, (state) => {
        state.state = State.PENDING;
        state.error = null;
      })
      .addCase(loginSamlStep2Thunk.fulfilled, (state) => {
        state.state = State.SUCCESS;
      })
      .addCase(loginSamlStep2Thunk.rejected, (state, action) => {
        state.state = State.FAILED;
        state.error = action.payload as SerializedError;
      })
      .addCase(loginSamlStep3Thunk.pending, (state) => {
        state.state = State.PENDING;
        state.error = null;
      })
      .addCase(loginSamlStep3Thunk.fulfilled, (state) => {
        state.state = State.SUCCESS;
      })
      .addCase(loginSamlStep3Thunk.rejected, (state, action) => {
        state.state = State.FAILED;
        state.error = action.payload as SerializedError;
      });
  },
});

export default samlSlice.reducer;
