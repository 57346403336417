import { defineMessages } from 'react-intl';

export default defineMessages({
  '0': {
    id: 'atostekApiError.0',
    defaultMessage: 'No atostekApiError. Operation completed successfully.',
  },
  '1000': {
    id: 'atostekApiError.1000',
    defaultMessage: 'No cards found.',
  },
  '1001': {
    id: 'atostekApiError.1001',
    defaultMessage: 'No card found with the given registration number, but other cards are available.',
  },
  '1002': {
    id: 'atostekApiError.1002',
    defaultMessage: 'User cancelled the operation (cancel PIN entry or card selection).',
  },
  '1005': {
    id: 'atostekApiError.1005',
    defaultMessage: 'User did not enter PIN or select card within the required time.',
  },
  '1006': {
    id: 'atostekApiError.1006',
    defaultMessage: 'ERA SmartCard program version is too old, older than the required minimum version.',
  },
  '1007': {
    id: 'atostekApiError.1007',
    defaultMessage: 'No card readers found.',
  },
  '1008': {
    id: 'atostekApiError.1008',
    defaultMessage: 'No cards found with the given certificate issuers. Other cards are available.',
  },
  '1009': {
    id: 'atostekApiError.1009',
    defaultMessage: 'Card is already in use. Cannot run two commands simultaneously on the same card.',
  },
  '1020': {
    id: 'atostekApiError.1020',
    defaultMessage: 'JSON message body is malformed.',
  },
  '1094': {
    id: 'atostekApiError.1094',
    defaultMessage: 'Command is not supported.',
  },
  '1095': {
    id: 'atostekApiError.1095',
    defaultMessage: 'Unknown command.',
  },
  '1100': {
    id: 'atostekApiError.1100',
    defaultMessage: 'System security container cannot be opened.',
  },
  '1101': {
    id: 'atostekApiError.1101',
    defaultMessage: "No access to the card's private key.",
  },
  '1102': {
    id: 'atostekApiError.1102',
    defaultMessage: 'Digest cannot be computed.',
  },
  '1103': {
    id: 'atostekApiError.1103',
    defaultMessage: 'Digest cannot be created.',
  },
  '1104': {
    id: 'atostekApiError.1104',
    defaultMessage: 'Digest cannot be signed.',
  },
  '1140': {
    id: 'atostekApiError.1140',
    defaultMessage:
      "Attempt to connect to the wrong user's ERA SmartCard instance. Check the port of the correct user's ERA SmartCard.",
  },
  '1150': {
    id: 'atostekApiError.1150',
    defaultMessage: 'Cannot connect to the card reader interface.',
  },
  '1151': {
    id: 'atostekApiError.1151',
    defaultMessage: 'Card readers cannot be retrieved.',
  },
  '1152': {
    id: 'atostekApiError.1152',
    defaultMessage: 'Card program cannot be opened.',
  },
  '1153': {
    id: 'atostekApiError.1153',
    defaultMessage: 'Invalid APDU response from the card.',
  },
  '1154': {
    id: 'atostekApiError.1154',
    defaultMessage: 'Card PIN directory cannot be set.',
  },
  '1155': {
    id: 'atostekApiError.1155',
    defaultMessage: 'Card PIN cannot be entered.',
  },
  '1156': {
    id: 'atostekApiError.1156',
    defaultMessage: 'Card PIN directories cannot be retrieved.',
  },
});
