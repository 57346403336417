import { createAction, createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';

import { getUserAPI } from 'services/user';
import { pingAtostekCardLogin } from 'services/atostek';
import { logout } from 'services/authentication/logout';

import { CurrentUserEnum, LoginAuth } from 'types/AuthRequired';
import { State } from 'types/State';

import { generateIdentifierHash } from 'utils/hash';
import { setClientSessionToken, getClientSessionToken, removeClientSessionToken } from 'utils/localStorage';
import { loginChannelAction, logoutChannelAction } from 'utils/channels/LoginSessionChannel';

import { RESET_STORE } from 'store/constants';
import { getLoginMethodSelection, getV2Auth, loginMethodSelection } from 'services/authentication/loginV2';
import { LoginMethod, LoginMethodSelection, PhaseId } from 'types/AuthenticationV2';
import history from 'utils/history';
import { getPrivileges } from 'store/privileges/slice';
import { fetchPreferences } from 'store/preferences/slice';

export type ClientSessionTokenParams = {
  userId: string;
  uniqueId: string;
};

export const AUTH_REDUCER_NAME = 'auth';

export const pingAtostekApiThunk = createAsyncThunk(`${AUTH_REDUCER_NAME}/pingAtostekApi`, async (_a, { dispatch }) => {
  const response = await pingAtostekCardLogin();
  // check if the response is a success or failure
  if (response.data?.ErrorCode !== 0) {
    dispatch({ type: RESET_STORE });
    await logout();
    throw new Error('Ping failed, logging out');
  }
  return response.data;
});

/*
 * This is a very important thunk: It is called after every major phase, and redirects to the next phase (dictated by the backend)
 * Note: override param prevents navigation. Used in LoginV2 when it's used in modal
 * IF YOU CHANGE THIS, CHECK LOGINMODAL AND AUTHV2
 * */
export const checkAuthV2StatusThunk = createAsyncThunk<{ goToPhaseId: PhaseId }, boolean | undefined>(
  `${AUTH_REDUCER_NAME}/checkAuthV2Status`,
  async (overrideNavigation: boolean = false, { dispatch }) => {
    const response = await getV2Auth();
    const notEresepti = !history.location.pathname.includes('eresepti');
    // user is not logged in, redirect to the correct phase
    if (response.data.goToPhaseId !== PhaseId.COMPLETE && !overrideNavigation) {
      history.replace(`/auth/${response.data.goToPhaseId}`);
    } else if (response.data.goToPhaseId !== PhaseId.COMPLETE && overrideNavigation) {
      // The user is not logged in, but we don't want to navigate (Modal behavior)
      return response.data;
    } else {
      // User is logged in, fetch user info, preferences and privileges and redirect to home
      const auth = await dispatch(checkLoginStatusThunk()).unwrap();
      // generate client session token so that different users cannot be logged in in different tabs
      await dispatch(
        generateClientSessionTokenThunk({
          userId: auth.currentDomacareUser.userId,
          uniqueId: auth.currentDomacareUser.id.toString(),
        }),
      ).unwrap();
      if (notEresepti) {
        await dispatch(fetchPreferences()).unwrap();
      }
      await dispatch(getPrivileges()).unwrap();
      if (!overrideNavigation) {
        const location = history.location;
        history.replace(location.pathname);
      }
    }
    return response.data;
  },
);

export const getLoginMethodSelectionThunk = createAsyncThunk(
  `${AUTH_REDUCER_NAME}/getLoginMethodSelection`,
  async () => {
    const response = await getLoginMethodSelection();
    return response.data;
  },
);

export const loginMethodSelectionThunk = createAsyncThunk(
  `${AUTH_REDUCER_NAME}/loginMethodSelection`,
  async (payload: { loginMethodId: LoginMethod; overrideNavigation?: boolean }, { dispatch }) => {
    const response = await loginMethodSelection({ loginMethodId: payload.loginMethodId });
    dispatch(checkAuthV2StatusThunk(payload.overrideNavigation));
    return response.data;
  },
);

export const checkLoginStatusThunk = createAsyncThunk(`${AUTH_REDUCER_NAME}/checkLoginStatus`, async () => {
  const response = await getUserAPI();

  return response.data;
});

const GENERATE_CLIENT_SESSION_TOKEN = `${AUTH_REDUCER_NAME}/generateClientSessionToken`;
const REMOVE_CLIENT_SESSION_TOKEN = `${AUTH_REDUCER_NAME}/removeClientSessionToken`;

export const generateClientSessionTokenThunk = createAsyncThunk(
  GENERATE_CLIENT_SESSION_TOKEN,
  async ({ userId, uniqueId }: ClientSessionTokenParams) => generateIdentifierHash(userId, uniqueId),
);

export const removeClientSessionTokenThunk = createAction(REMOVE_CLIENT_SESSION_TOKEN);
export const showLoginModal = createAction(`${AUTH_REDUCER_NAME}/showLoginModal`);
export const hideLoginModal = createAction(`${AUTH_REDUCER_NAME}/hideLoginModal`);

export type AuthState = {
  userInfo: LoginAuth;
  state: State;
  loginMethodSelectState: State;
  loginMethodSelectError: null | SerializedError;
  loginMethods?: LoginMethodSelection[];
  error: null | SerializedError;
  loggedIn: boolean;
  previousState: null | string;
  userType: CurrentUserEnum;
  currentDB: null | string;
  clientSessionToken: null | string;
  showLoginModal: boolean;
  phaseId: PhaseId | null;
};

const initialState = {
  userInfo: {},
  state: State.NOT_STARTED,
  loginMethodSelectState: State.NOT_STARTED,
  loginMethodSelectError: null,
  loginMethods: undefined,
  loggedIn: false,
  previousState: null,
  currentDB: null,
  clientSessionToken: null,
  showLoginModal: false,
  phaseId: null,
} as AuthState;

const slice = createSlice({
  name: AUTH_REDUCER_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(checkLoginStatusThunk.pending, (draft) => {
      draft.state = State.PENDING;
      draft.error = null;
    });
    builder.addCase(checkLoginStatusThunk.fulfilled, (draft, action) => {
      draft.state = State.SUCCESS;
      draft.error = null;
      draft.userInfo = action.payload;
      draft.loggedIn = action.payload.loginState.loggedIn;
      draft.userType = action.payload.loginState.userType as CurrentUserEnum;
      draft.currentDB = action.payload.currentDb;
      // if page is refreshed, but there is no clientSessionToken in the state, but there is one in the localStorage, it means that the session is not stale
      const token = getClientSessionToken();
      if (draft.clientSessionToken === null && token) {
        draft.clientSessionToken = token;
      }
    });
    builder.addCase(checkLoginStatusThunk.rejected, (draft, action) => {
      draft.state = State.FAILED;
      draft.loggedIn = false;
      draft.error = action.error;
      console.log('cannot get login state');
      console.log(action.error);
    });
    builder.addCase(generateClientSessionTokenThunk.fulfilled, (draft, action) => {
      const { payload } = action;
      draft.clientSessionToken = payload;
      setClientSessionToken(payload);
      loginChannelAction();
      draft.showLoginModal = false;
    });
    builder.addCase(removeClientSessionTokenThunk, (draft) => {
      draft.clientSessionToken = null;
      removeClientSessionToken();
      logoutChannelAction();
    });
    builder.addCase(showLoginModal, (draft) => {
      draft.showLoginModal = true;
    });
    builder.addCase(hideLoginModal, (draft) => {
      draft.showLoginModal = false;
    });
    // case for pingAtostekApiThunk
    builder.addCase(pingAtostekApiThunk.pending, (draft) => {
      draft.error = null;
    });
    builder.addCase(pingAtostekApiThunk.fulfilled, (draft) => {
      draft.state = State.SUCCESS;
      draft.error = null;
    });
    builder.addCase(pingAtostekApiThunk.rejected, (draft, action) => {
      draft.state = State.FAILED;
      draft.error = action.error;
    });
    // cases for checkAuthV2StatusThunk
    builder.addCase(checkAuthV2StatusThunk.pending, (draft) => {
      draft.state = State.PENDING;
      draft.error = null;
    });
    builder.addCase(checkAuthV2StatusThunk.fulfilled, (draft, action) => {
      draft.state = State.SUCCESS;
      draft.error = null;
      draft.phaseId = action.payload.goToPhaseId;
    });
    builder.addCase(checkAuthV2StatusThunk.rejected, (draft, action) => {
      draft.state = State.FAILED;
      draft.error = action.error;
    });

    // cases for login method selection
    builder.addCase(loginMethodSelectionThunk.pending, (draft) => {
      draft.loginMethodSelectState = State.PENDING;
      draft.loginMethodSelectError = null;
    });
    builder.addCase(loginMethodSelectionThunk.fulfilled, (draft) => {
      draft.loginMethodSelectState = State.SUCCESS;
      draft.loginMethodSelectError = null;
    });
    builder.addCase(loginMethodSelectionThunk.rejected, (draft, action) => {
      draft.loginMethodSelectState = State.FAILED;
      draft.loginMethodSelectError = action.error;
    });
    builder.addCase(getLoginMethodSelectionThunk.pending, (draft) => {
      draft.loginMethodSelectState = State.PENDING;
      draft.loginMethodSelectError = null;
    });
    builder.addCase(getLoginMethodSelectionThunk.fulfilled, (draft, action) => {
      draft.loginMethodSelectState = State.SUCCESS;
      draft.loginMethodSelectError = null;
      draft.loginMethods = action.payload;
    });
    builder.addCase(getLoginMethodSelectionThunk.rejected, (draft, action) => {
      draft.loginMethodSelectState = State.FAILED;
      draft.loginMethodSelectError = action.error;
    });
  },
});

// export const {} = slice.actions;

export default slice.reducer;
