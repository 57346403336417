import { FC } from 'react';
import { useIntl } from 'react-intl';

import Modal, { ModalProps, ModalSize } from 'common/Modal';

import loginMessages from 'Login/messages';
import { featureEnabled } from 'utils/features';
import { Features } from 'types/Features';
import Login from 'Login/components/Login/Login';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthRequiredDomain } from 'store/auth/selectors';
import { AppDispatch } from 'store';
import { useEffectOnce } from 'usehooks-ts';
import { checkAuthV2StatusThunk } from 'store/auth/slice';
import AuthV2 from 'Login/AuthV2/AuthV2';

const LoginModal: FC<Pick<ModalProps, 'show'>> = ({ show }) => {
  const { formatMessage } = useIntl();
  const state = useSelector(selectAuthRequiredDomain());
  const appDispatch = useDispatch<AppDispatch>();

  useEffectOnce(() => {
    if (featureEnabled(Features.AuthV2)) {
      appDispatch(checkAuthV2StatusThunk(true));
    }
  });

  if (featureEnabled(Features.AuthV2)) {
    return (
      <Modal
        blurBackground
        show={show}
        title={formatMessage(loginMessages.youHaveBeenLoggedOut)}
        onHide={() => {}}
        size={ModalSize.SM}
        error={
          <>
            {formatMessage(loginMessages.sessionInvalidated)}. {formatMessage(loginMessages.loginAgain)}
          </>
        }
      >
        <Modal.Body>{!!state.phaseId && <AuthV2 params={{ feature: state.phaseId }} inModal />}</Modal.Body>
        <Modal.Footer />
      </Modal>
    );
  }
  return (
    <Modal
      blurBackground
      show={show}
      title={formatMessage(loginMessages.youHaveBeenLoggedOut)}
      onHide={() => {}}
      size={ModalSize.SM}
      error={
        <>
          {formatMessage(loginMessages.sessionInvalidated)}. {formatMessage(loginMessages.loginAgain)}
        </>
      }
    >
      <Modal.Body>
        <Login />
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
};

export default LoginModal;
