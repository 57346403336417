import api from 'utils/api';

export type SAMLStep1Response = {
  url: string;
};

export const postLoginV2Saml = (payload: { client: string } = { client: 'WEB' }) =>
  api.post<SAMLStep1Response>('authentication/login/saml', payload);

// /authentication/login/saml/step2
export const postLoginV2SamlStep2 = (payload: { SAMLResponse: string }) =>
  api.post('authentication/login/saml/step2', payload);

// /authentication/login/saml/step3
export const postLoginV2SamlStep3 = (payload: { SAMLResponse: string }) =>
  api.post('authentication/login/saml/step3', payload);
