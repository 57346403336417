// /authentication/deviceregistrations:

import api from 'utils/api';
import { SrpPayload } from 'types/AuthenticationV2';

export const getV2DeviceRegistrations = () => api.get('authentication/deviceregistrations');

/*
 * These are the new SRP stuff (when they work)
 * */
export const postV2SrpSMS = (payload: { username: string; implementation: string; phoneNumber: string }) =>
  api.post<SrpPayload>('/authentication/deviceregistrations/methods/srpsms', payload);

export const postV2SrpSMSStep2 = (payload: { AHex: string; M1Hex: string }) =>
  api.post<{ M2: string }>('/authentication/deviceregistrations/methods/srpsms/step2', payload);

export const postV2SrpSMSStep3 = (payload: { token: string }) =>
  api.post('/authentication/deviceregistrations/methods/srpsms/step3', payload);

export const getV2TenantSelection = () => api.get('/authentication/tenantselection');

export const postV2TenantSelection = (payload: { tenant: string }) =>
  api.post('/authentication/tenantselection', payload);
