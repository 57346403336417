import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { loginMethodSelection, postLoginV2Srpstep1, postLoginV2Srpstep2 } from 'services/authentication/loginV2';
import { checkAuthV2StatusThunk } from 'store/auth/slice';
import { State } from 'types/State';
import SRPClient from 'utils/thinbus-srp/thinbus-srp6client-sha256';
import { LoginMethod } from 'types/AuthenticationV2';
import store from 'store';

export const LOGIN_V2_REDUCER_NAME = 'loginV2';

export const loginV2Step1Thunk = createAsyncThunk(
  `${LOGIN_V2_REDUCER_NAME}/loginV2Step1`,
  async (payload: { username: string; password: string }, { dispatch }) => {
    await loginMethodSelection({ loginMethodId: LoginMethod.SRP });
    const response = await postLoginV2Srpstep1({
      username: payload.username,
      implementation: 'THINBUS',
    });
    dispatch(loginV2Step2Thunk({ response: response.data, username: payload.username, password: payload.password }));
    return { response: response.data, username: payload.username, password: payload.password };
  },
);

export const loginV2Step2Thunk = createAsyncThunk(
  `${LOGIN_V2_REDUCER_NAME}/loginV2Step2`,
  async (payload: { response: any; username: string; password: string }) => {
    const srpcli = new SRPClient({
      N_base10: payload.response.NDecimal,
      g_base10: payload.response.gDecimal,
      k_base16: payload.response.kHex,
    });
    (srpcli as any).step1(payload.username, payload.password);
    const srpCreds: { A: string; M1: string } = (srpcli as any).step2(payload.response.sHex, payload.response.BHex);
    const step2Payload = {
      AHex: srpCreds.A,
      M1Hex: srpCreds.M1,
    };
    await postLoginV2Srpstep2(step2Payload);
    const overrideNavigation = store.getState().auth.showLoginModal;
    await store.dispatch(checkAuthV2StatusThunk(overrideNavigation)).unwrap();
  },
);

export type LoginV2State = {
  state: State;
  error: null | SerializedError;
};

export const initialState: LoginV2State = {
  state: State.NOT_STARTED,
  error: null,
};

const loginV2Slice = createSlice({
  name: LOGIN_V2_REDUCER_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginV2Step1Thunk.pending, (draft) => {
      draft.state = State.PENDING;
      draft.error = null;
    });
    builder.addCase(loginV2Step1Thunk.fulfilled, (draft) => {
      draft.state = State.SUCCESS;
      draft.error = null;
    });
    builder.addCase(loginV2Step1Thunk.rejected, (draft, action) => {
      draft.state = State.FAILED;
      draft.error = action.error;
    });
    builder.addCase(loginV2Step2Thunk.pending, (draft) => {
      draft.state = State.PENDING;
      draft.error = null;
    });
    builder.addCase(loginV2Step2Thunk.fulfilled, (draft) => {
      draft.state = State.SUCCESS;
      draft.error = null;
    });
    builder.addCase(loginV2Step2Thunk.rejected, (draft, action) => {
      draft.state = State.FAILED;
      draft.error = action.error;
    });
  },
});

export default loginV2Slice.reducer;
