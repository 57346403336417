import { ClinicalDocumentCode } from 'EResepti/codeTypes';

export type DB = { publicName: string; internalName: string };

export enum AuthLevel {
  DB_SELECT_NEEDED = 'DB_SELECT_NEEDED',
  AUTHORIZED = 'AUTHORIZED',
  ERROR = 'ERROR',
}

export type DBSelection = { databaseName: string; authLevel: AuthLevel };

export type Status = {
  authLevel: AuthLevel;
  possibleDatabases: DB[];
  errorMessage?: string;
};

// not sure about this
export enum SIGN_MODE {
  INACTIVE = 'INACTIVE',
  SINGLE = 'SINGLE',
  MULTISIGN = 'MULTISIGN',
  REPUDIATE = 'REPUDIATE',
  DONE = 'DONE',
  RENEWAL = 'RENEWAL',
  ERROR = 'ERROR',
}

export enum DurationUnitStore {
  YEAR = 'year',
  MONTH = 'month',
  DAY = 'day',
}

export const DurationUnitForDiff = {
  year: 'years',
  month: 'months',
  day: 'days',
};

export enum RenewalRequestStatus {
  UNPROCESSED = 'UNPROCESSED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  ERROR = 'ERROR',
}

// BE specific stuff
export enum PrescriptionStatus {
  SIGNATURE_REQUESTED = 'SIGNATURE_REQUESTED',
  ERROR = 'ERROR',
  SAVED = 'SAVED',
  SENT = 'SENT',
  SIGNED = 'SIGNED',
}

export enum IterationFrequencyUnit {
  DAY = 'DAY',
  YEAR = 'YEAR',
}

export type RenewalRequest = {
  id: number;
  oid: string;
  versionNumber: number;
  setId: string;
  targetOrganizationOid: string;
  targetOrganizationName: string;
  status: string;
  authorName: string;
  authorUserId: number;
  medicationDescription: string;
  amountDescription: string;
  creationTime: string;
  patientName: string;
  patientSsn: string;
  prescriptionOid: string;
  prescriptionSetId: string;
  prescriptionTypeCode: string;
  prescriptionAuthorName: string;
  message: string;
  isPatientInformed: boolean;
  prescriptionOrganizationOid: string;
  prescriptionOrganizationName: string;
  acceptedPrescriptionOid: string;
  createdLocally: boolean;
};

export type RenewalRequestQuery = {
  consentCode: number;
};

export type NewRenewalRequest = Pick<RenewalRequest, 'prescriptionSetId' | 'targetOrganizationOid' | 'message'> & {
  consentCode: number;
  renewalAgreementCode: number;
};

export enum MedicineType {
  VNR = 'VNR',
  ATC = 'ATC',
  NONMEDICAL = 'NONMEDICAL',
  COCKTAIL = 'COCKTAIL',
}

export type Substance = {
  amountValue: string;
  amountUnit: string;
  amountText: string;
  uncodedName: string;
  primarySubstance: boolean;
  orderNumber: number;
  atcName: string;
  substance: ATCMedication; // cgeck this
};

type DescriptionContent = {
  content: string;
  orderNumber: number;
};

export enum DISPENSE_STATUS {
  NOT_DELIVERED = 1,
  PARTIALLY_DELIVERED,
  FULLY_DELIVERED,
}

export type SpecialStatement = {
  code: string;
  name: string;
  doctorNote: string;
  doctorNoteSv: string;
  dateDesc: string;
  requireDate: boolean;
};

// fields that aren't relevant for the backend, but used in validation in the frontend
// will be plucked out before sending to the backend
export type PrescriptionFormMeta = {
  modification: boolean;
  dateDesc?: string;
  separateStatement?: SpecialStatement & { value: string; label: string };
  compensationClasses?: Compensation[] | null;
  currentVNRMedication?: VNRMedication | null;
  doctorNoteAccepted?: boolean;
};

export type Illness = {
  illnessCode: string;
  illnessName: string;
  specialStatement: SpecialStatement[];
};

export type Compensation = { compCode: string; illnesses?: Illness[] };

export enum BiologicalMedication {
  NO = 0,
  YES = 1,
}

export enum PrescriptionType {
  PRESCRIPTION = '1',
  HOSPITAL_PHARMACY_PRESCRIPTION = '2',
  FOREIGN_PRESCRIPTION = '3',
}

export type PrescriptionBase = {
  oid: string;
  childWeightUnit?: string;
  childWeightValue?: number;
  versionNumber: number;
  effectiveTimeStart: string;
  setId: string;
  messageType: number;
  queryReasonCode: number;
  consentCode: number;
  dosageText: string;
  sic: boolean;
  medicineType: MedicineType;
  recipeCategory: number;
  descriptionContent: DescriptionContent[];
  authorContent: any[];
  quantityAsText: string;
  atcCode: string;
  medicineATC: PrescriptionMedicineATC;
  vnrId: string;
  vnrName: string;
  substances: any[];
  strengthAndUnit: string;
  uncodedProductName: string;
  underagePatientInforming?: boolean;
  pharmaceuticalForm: string;
  medicalProfessional: PrescriptionMedicalProfessional;
  patient: PrescriptionPatient;
  recipeType: PrescriptionType;
  medicationChangeProhibition: boolean;
  dosageDistribution: boolean;
  partialPackage: boolean;
  physicianFee: string;
  careTypeCodeSickness: boolean;
  careTypeCodeWorkAccident: boolean;
  careTypeCodeOther: boolean;
  permanentMedication: boolean;
  drugUsageStart: boolean;
  narcotic: boolean;
  isCancelled: boolean;
  cancellationPartyCode: string;
  renewalResponseHasPatientBeenInformed: boolean;
  renewalProhibition: boolean;
  renewalStatusCode: number;
  dispenseStatusCode: DISPENSE_STATUS;
  activeStatusCode: string;
  reservationStatusCode: number;
  physicianFeeSpecialized: boolean;
  infectionMedication: boolean;
  fullyDelivered: boolean;
  language: string;
  effectiveTime: string;
  endingTime: string;
  dosageType: string;
  createdLocally: boolean;
  authorTime: PrescriptionAuthorTime;
  description: string;
  cancelled: boolean;
  status: PrescriptionStatus;
  iterationCount: 0 | 1 | 2 | 3;
  iterationFrequency: number;
  iterationFrequencyUnit: IterationFrequencyUnit;
  pkvPrescriptionCode: string;
  packageSize: number;
  productCategory: string;
  packageAsText: string;
  packageCount: number;
  quantityValue: number;
  quantityUnit: string;
  effectiveTimeDuration: string;
  effectiveTimeDurationUnit: DurationUnitStore;
  purposeAsText: string;
  messageToPharmacy: string;
  renewalProhibitionReason: string;
  iterationAsText: string;
  reward: string;
  separateCode: string;
  doctorsNote: string;
  dosageDistributionAsText: string;
  ATCSubstance: string;
  packaging: string;
  modificationReasonCode?: number;
  modificationReason?: string;
  patientIdentificationCode?: string;
  patientIdentificationCodeText?: string;
  instructions: string;
  uncodedName: string;
  isRenewable: boolean;
  reasoningForExpensiveBiosimilar: string;
  separateStatementDate?: string;
  separateStatementCode?: string;
  biologicalMedication: BiologicalMedication;
};

export type RenewalDocument = PrescriptionBase & {
  relatedRenewalDocumentSetID: string;
  relatedRenewalDocumentOID: string;
};

export type PrescriptionPayload = PrescriptionBase & {
  patient: PrescriptionPatientPayload;
  meta: PrescriptionFormMeta;
  currentMedicineAtcMeta: ATCMedication;
};

export type ShortPrescription = {
  id: number;
  oid: string;
  versionNumber: number;
  setId: string;
  messageType: number;
  status: PrescriptionStatus;
  authorName: string;
  authorUserId: number;
  dosageText: string;
  medicationDescription: string;
  creationTime: string;
  signatureTime: string;
  errorMessage: string;
  developerMessage: string;
  documentType: string;
  patientName: string;
  patientSsn: string;
};

export type PrescriptionMetaQuery = {
  // clinicalDocumentEffectiveTime: string;
  // clinicalDocumentEffectiveTimeEnd: string;
  consentCode: number;
  isRenewable: boolean;
  patientSSN: string;
  queryReasonCode: number;
  startDate: string;
  endDate: string;
};

export type Amendment = {
  modificationReasonCode: string;
  modificationReason: string;
} & Partial<PrescriptionPayload>;

// form related
export type PrescriptionKey = keyof PrescriptionPayload;
export type PrescriptionValue = PrescriptionPayload[PrescriptionKey];

export interface PrescriptionQuery {
  setID: string[];
  consentCode: number;
  queryReasonCode: number;
  patientSSN: string;
  clinicalDocumentCode?: ClinicalDocumentCode[]; // determines which type of document will be returned
}

// prescription field type, name is key of prescription, value is value of prescription
export type PrescriptionField = {
  name: PrescriptionKey;
  value: PrescriptionValue;
};

export type PrescriptionMedicineATC = {
  atcCode: string;
  atcCodeNameFi: string;
  atcCodeName: string;
};

export type PrescriptionMedicalProfessionalNameParts = {
  type: string;
  value: string;
};

export type PrescriptionMedicalProfessionalNameFirstNamesWithQualifier = {
  type: string;
  value: string;
};

export type PrescriptionMedicalProfessionalName = {
  parts: PrescriptionMedicalProfessionalNameParts[];
  prefixes: any[];
  suffixes: any[];
  lastName: string;
  firstName: string;
  firstNames: string[];
  firstNamesWithQualifier: PrescriptionMedicalProfessionalNameFirstNamesWithQualifier[];
};

export type PrescriptionMedicalProfessional = {
  lastName: string;
  firstName: string;
  fullName: string;
  svId: string;
  name: PrescriptionMedicalProfessionalName;
};

export type PrescriptionPatientNameParts = {
  type: string;
  value: string;
};

export type PrescriptionPatientNameFirstNamesWithQualifier = {
  type: string;
  value: string;
};

export type PrescriptionPatientName = {
  parts: PrescriptionPatientNameParts[];
  prefixes: any[];
  suffixes: any[];
  lastName: string;
  firstName: string;
  firstNames: string[];
  firstNamesWithQualifier: PrescriptionPatientNameFirstNamesWithQualifier[];
};

// comes with the prescription
export type PrescriptionPatient = {
  ssn: string;
  name: PrescriptionPatientName;
  lastName: string;
  firstName: string;
  fullName: string;
  age: number;
};

// for posting
export type PrescriptionPatientPayload = Pick<PrescriptionPatient, 'ssn' | 'lastName' | 'firstName'>;

export type VNRMedication = {
  atcCode: string;
  fullName: string;
  substitutionCode: string;
  substitutionCodeGroup: string;
  vnrId: string;
  narcotic: boolean;
  pkvCode: string;
  biologicalMedication: number;
  compensationClasses: Compensation[];
  packaging: string;
  packageAsText: string;
  psychoClass: string;
  psychoClassFull: string;
  medicationAtc: ATCMedication;
  beingPhasedout: boolean;
};

export type ATCMedication = {
  atcCode: string;
  fullName: string;
  atcCodeName: string;
  atcCodeNameFi: string;
  atcCodeNameSv: string;
  narcotic: boolean;
  pkvCode: string;
};

export type PrescriptionAuthorTime = unknown;

// type for repudiation. Has these fields: setId, consentCode, repudiationTypeCode, repudiationReasonCode, repudiationReasonText
export type Repudiation = {
  setId: string;
  consentCode: number;
  repudiationTypeCode: number;
  repudiationReasonCode: number;
  repudiationReasonText: string;
};

export type UnverifiedPrescription = {
  id: number;
  oid: string;
  versionNumber: number;
  setId: string;
  messageType: number;
  status: string;
  authorName: string;
  authorUserId: number;
  medicationDescription: string;
  creationTime: string;
  documentType: string;
  patientName: string;
  patientSsn: string;
};

export type PrintParams = Pick<PrescriptionMetaQuery, 'queryReasonCode' | 'patientSSN' | 'consentCode'> & {
  setId: string;
};

export type Restrictions = {
  atcRestrictions: [string];
  pkvRestrictions: [string];
};

export type Audit = {
  id: number;
  userId: number;
  eventTime: string;
  kantaOperationType: string;
  kantaOperationName: string;
  interactionOID: string;
  performerCardId: string;
  targetPatientName: string;
  returnMessageOid: string;
  returnMessageCount: number;
  queryConsentCode: number;
  queryReasonCode: number;
  queryEndDate: string;
  read: boolean;
  create: boolean;
  amend: boolean;
  repudiate: boolean;
};
export type SubstanceCocktail = {
  substance: ATCMedication | null;
  uncodedName: string;
  amountText: string;
  uncodedProductName?: string;
};

// TODO: Refactor, because this is a copy of the same component in Erecipe
export enum PrintSummaryFormFields {
  consentCode = 'consentCode',
  queryReason = 'queryReason',
  queryReasonCode = 'queryReasonCode',
  clinicalDocumentEffectiveTime = 'clinicalDocumentEffectiveTime',
  clinicalDocumentEffectiveTimeEnd = 'clinicalDocumentEffectiveTimeEnd',
  patientCannotSignDescription = 'patientCannotSignDescription',
  patientCannotSign = 'patientCannotSign',
  disclosureAtEmergency = 'disclosureAtEmergency',
  queryOnBehalf = 'queryOnBehalf',
}

export type CurrentUserRestrictions = {
  vrkCardId: string;
  startDate: string;
  restrictionText: string;
  restrictionCode: string;
  restrictionCodeSystem: string;
};

export type CurrentUser = {
  userId: number;
  prescriptionWritePrivilege: boolean;
  prescriptionReadPrivilege: boolean;
  restrictions: CurrentUserRestrictions[];
};

// the state of eResepti
export type EReseptiState = {
  currentUser: CurrentUser;
};

export type SoteOrganisation = {
  oid: string;
  longName: string;
  abbreviation: string;
  name: string;
};
