import api from 'utils/api';

import { BookingItem } from 'Booking/types/BookingItem';
import { GetAllTasksParams } from 'Booking/types/Parameters/GetAllTaskskParams';
import { TaskDetailParams } from 'Booking/types/Parameters/TaskDetailParams';
import { Service } from 'Booking/types/Service';
import { BookingItemAuctionComment } from 'Booking/types/Timeline/BookingItemAuctionComment';
import { TaskAdminPanelFilters } from 'Booking/containers/TaskAdminPanelModal/types';
import { TaskSearchResult } from 'Booking/types/TaskSearch/TaskSearchResult';

/**
 * @description patch task by id with an employeeId
 * @param {number} taskId id of the task
 * @param {number} employeeId id of the employee
 */
export const patchTaskByEmployeeId = (taskId: number, employeeId: undefined | number) =>
  api.patch<BookingItem[]>(`/booking/tasks/${taskId}`, { employeeId: employeeId || null });

/**
 * @description sends SMS to user when a task is patched
 * @param {number} taskId task id
 * @param message
 */
export function SMSToUser(taskId: number, message = null) {
  return api.post(`/booking/tasks/${taskId}/sms/user/confirm`, { messageTemplate: message });
}

/**
 * @description sends SMS to customer when a task is patched
 * @param {number} id task id
 */
export function SMSToCustomer({ taskId, messageTemplate }: never) {
  return api.post(`/booking/tasks/${taskId}/sms/customer/confirm`, messageTemplate);
}

export const getEmployeeTasks = (id: number, params: any) => api.get<BookingItem[]>(`/users/${id}/tasks`, { params });

export const getAllTasks = (params?: Partial<GetAllTasksParams>) => api.get<BookingItem[]>('booking/tasks', { params });

export const getTaskDetail = (id: number, params: Partial<TaskDetailParams>) =>
  api.get<BookingItem>(`booking/tasks/${id}`, { params });

export const editTask = (id: number, payload: Partial<BookingItem>, params?: { applyToTasksInRepetition: boolean }) =>
  api.patch<BookingItem>(`booking/tasks/${id}`, payload, { params });

export const cancelOrResumeTask = (taskId: number, payload: { canceled: boolean; reason: string }) =>
  api.patch(`booking/tasks/${taskId}/cancel`, payload);

export const patchService = (taskId: number, serviceId: number, payload: Partial<Service>) =>
  api.patch(`booking/tasks/${taskId}/services/${serviceId}`, payload);

export const postNewServices = (taskId: number, payload: Partial<Service>[]) =>
  api.post(`booking/tasks/${taskId}/services`, payload);

export const addTask = (payload: Partial<BookingItem>) => api.post<BookingItem>(`booking/tasks`, payload);

export const deleteService = (taskId: number, serviceId: number) =>
  api.delete(`booking/tasks/${taskId}/services/${serviceId}`);

export const deleteTask = (id: number) => api.delete(`booking/tasks/${id}`);

export const getComments = (id: number) => api.get<BookingItemAuctionComment>(`booking/tasks/${id}/comments`);

export const createComments = (id: number, payload: { comment: string }) =>
  api.post<BookingItemAuctionComment>(`booking/tasks/${id}/comments`, payload);

export const updateComments = (id: number, payload: { comment: string }) =>
  api.patch<BookingItemAuctionComment>(`booking/tasks/${id}/comments`, payload);

export const deleteComments = (taskId: number) => api.delete(`booking/tasks/${taskId}/comments`);

export const searchTasks = (params: TaskAdminPanelFilters) =>
  api.get<TaskSearchResult[]>('booking/tasks/search', { params });
