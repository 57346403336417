import { Customer } from 'types/Customer';
import { Attachment } from 'types/Attachments';

import api from 'utils/api';

import { AttachmentUploadFileMeta } from 'services/attachments';
import { AddEditCustomerPayload } from 'Customers/types/AddEditCustomerPayload';

export type CustomerListQuery = {
  limit: number;
  search: string;
  sort: string;
  unitIds: number[];
  expand: string;
  page: number;
  booking: boolean;
  select: string;
};

export type CustomersInUnitQuery = CustomerListQuery & { ongoingRestrictionEvent: boolean };

type CustomerSearchResult = {
  unitId: number;
  count: number;
};

type DeletedCustomers = {
  currentPage: number;
  totalPages: number;
  totalResults: number;
  data: Customer[];
};

type GetCustomerByIdParams = {
  expandPresentByStartDate: string;
  expandPresentByEndDate: string;
  select: string;
  expand: string;
};

export const getCustomerList = (params?: Partial<CustomerListQuery>) => api.get<Customer[]>(`customers`, { params });

export const getCustomersInUnit = (unitId: number, params?: Partial<CustomersInUnitQuery>) =>
  api.get<Customer[]>(`units/${unitId}/customers`, { params });

export const getCustomerById = (customerId: number, params?: Partial<GetCustomerByIdParams>) =>
  api.get<Customer>(`customers/${customerId}`, { params });

export const getCustomerSearchMatches = (search: string) =>
  api.get<CustomerSearchResult[]>(`customers/matches`, { params: { search } });

export const getCustomerSearchInUnit = (unitIds: number[], search: string) =>
  getCustomerList({ unitIds, search, sort: 'lastName,firstName' });

export const getDeletedCustomers = (params?: Partial<CustomerListQuery>) =>
  api.get<DeletedCustomers>(`customers/deleted`, { params });

export const getDeletedCustomerSearchMatches = (search: string) =>
  api.get<number>(`customers/matches/deleted`, { params: { search } });

export const getCustomerProfileImage = (customerId: number) =>
  api.get<Attachment>(`customers/${customerId}/profileimage`);

export const getCustomerProfileThumbnail = (customerId: number) =>
  api.get<Attachment>(`customers/${customerId}/profileimage/thumbnail`);

export const addCustomerProfileImage = (customerId: number, payload: AttachmentUploadFileMeta) =>
  api.post(`customers/${customerId}/profileimage`, payload);

export const deleteCustomerProfileImage = (customerId: number) => api.delete(`customers/${customerId}/profileimage`);

export const postNewCustomer = (payload: Partial<AddEditCustomerPayload>) => api.post<Customer>(`customers`, payload);

export const updateCustomer = (customerId: number, payload: Partial<AddEditCustomerPayload>) =>
  api.patch<Customer>(`customers/${customerId}`, payload);

export const patchCustomerAdditionalInfo = (id: number, params: Partial<Customer>) =>
  api.patch<Customer>(`customers/${id}/additionalinfo`, params);
