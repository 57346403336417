import { defineMessages } from 'react-intl';

export default defineMessages({
  user: {
    id: 'SideNavItem.user',
    defaultMessage: 'Username',
  },
  tasks: {
    id: 'SideNavItem.tasks',
    defaultMessage: 'Tasks',
  },
  booking: {
    id: 'SideNavItem.bookings',
    defaultMessage: 'Bookings',
  },
  auction: {
    id: 'SideNavItem.auction',
    defaultMessage: 'Task Auction',
  },
  customers: {
    id: 'SideNavItem.customers',
    defaultMessage: 'Customers',
  },
  raiStats: {
    id: 'SideNavItem.raiStats',
    defaultMessage: 'Statistics',
  },
  wallet: {
    id: 'SideNavItem.wallet',
    defaultMessage: 'Wallet',
  },
  calendar: {
    id: 'SideNavItem.calendar',
    defaultMessage: 'Calendar',
  },
  messages: {
    id: 'SideNavItem.messages',
    defaultMessage: 'Messages',
  },
  settings: {
    id: 'SideNavItem.settings',
    defaultMessage: 'Settings',
  },
  logout: {
    id: 'SideNavItem.logout',
    defaultMessage: 'Logout',
  },
  diagnosis: {
    id: 'SideNavItem.diagnosis',
    defaultMessage: 'Diagnosis',
  },
  duties: {
    id: 'SideNavItem.duties',
    defaultMessage: 'Duties',
  },
  billing: {
    id: 'SideNavItem.billing',
    defaultMessage: 'Billing',
  },
  workPlanning: {
    id: 'SideNavItem.workPlanning',
    defaultMessage: 'Work planning',
  },
  availabilities: {
    id: 'SideNavItem.availabilities',
    defaultMessage: 'Availabilities',
  },
  contracts: {
    id: 'SideNavItem.contracts',
    defaultMessage: 'Contracts',
  },
  frontPage: {
    id: 'SideNavItem.frontPage',
    defaultMessage: 'Front Page',
  },
  payroll: {
    id: 'SideNavItem.payroll',
    defaultMessage: 'Payroll',
  },
  exportArchive: {
    id: 'SideNavItem.exportArchive',
    defaultMessage: 'Export Archive',
  },
  users: {
    id: 'SideNavItem.users',
    defaultMessage: 'Users',
  },
  corrections: {
    id: 'SideNavItem.corrections',
    defaultMessage: 'Corrections',
  },
  customersKeys: {
    id: 'SideNavItem.customersKeys',
    defaultMessage: 'customers keys',
  },
});
