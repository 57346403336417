/* eslint-disable no-undef */
/*
 * This function replaces the , in numbers with the .
 * Don't use it for strings containing characters
 */

export const replaceComma = (value: string) => value.replace(/,/g, '.');

/**
 * Generates a random id. Useful when debugging stuff
 */
export const generateRandomId = () =>
  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

export const randomNumber = (max = 200) => Math.floor(Math.random() * Math.floor(max));
export const randomString = () =>
  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

/** to check if the text includes in the searched string */
export const checkTextIncludes = (text: string, search: string) => {
  if (!text && search) return false; // Keep ignoring non-existent text
  return text.toLowerCase().replace(/\s/g, '').includes(search.toLowerCase().replace(/\s/g, ''));
};

export const updateItemInArray = <T>(array: T[], item: T, comparable: keyof T) => {
  const newArray = [...array];
  const itemIndex = array.findIndex((a) => a[comparable] === item[comparable]);
  newArray.splice(itemIndex, 1, item);
  return newArray;
};

// This only converts decimal to fixed precision and leaves the non-decimal numbers
export const decimalToFixedPrecision = (decimal: number, precision: number) =>
  decimal.toFixed(precision).replace('.00', '');

// This converts decimal to and removes the trailing zeros
export const removeTrailingZeros = (val: string) => {
  // Convert the number to a string
  let numberStr = val;

  // If the number has a decimal point
  if (numberStr.includes('.')) {
    // Remove trailing zeros after the decimal point
    numberStr = numberStr.replace(/\.?0+$/, '');
  }

  // Convert the modified string back to a number
  return parseFloat(numberStr);
};

export const pickNonUndefinedValues = <T extends Record<string, unknown>>(values: T) =>
  Object.keys(values)
    .filter((k) => values[k] !== undefined)
    .reduce((acc, k) => ({ ...acc, [k]: values[k] }), {});

// checkUserNameIncludes. Uses checkTextIncludes but for usernames, also in reverse order
export const checkUserNameIncludes = (user: { firstName: string; lastName: string }, search: string) => {
  const { firstName, lastName } = user;
  return (
    checkTextIncludes(firstName, search) ||
    checkTextIncludes(lastName, search) ||
    checkTextIncludes(`${firstName} ${lastName}`, search) ||
    checkTextIncludes(`${lastName} ${firstName}`, search)
  );
};
