import LoginWrapper from 'Login/components/LoginWrapper/LoginWrapper';
import useReducerThunk from 'hooks/useReducerThunk';
import reducer, {
  fetchDeviceRegistrationsThunk,
  initialState,
  postTenantSelectionThunk,
} from 'Login/TenantSelection/slice';
import { useEffectOnce } from 'usehooks-ts';
import { State } from 'types/State';
import Spinner from 'common/Spinner';
import Button from 'common/Button';
import { Tenant } from 'types/AuthenticationV2';
import { OverrideNavigation } from 'Login/AuthV2/types';
import { FC } from 'react';

const TenantSelection: FC<OverrideNavigation> = () => {
  const [{ state, tenants, error }, dispatch] = useReducerThunk(reducer, initialState);
  useEffectOnce(() => {
    dispatch(fetchDeviceRegistrationsThunk());
  });

  const handleTenantSelection = (tenant: Tenant) => {
    dispatch(postTenantSelectionThunk(tenant));
  };

  return (
    <LoginWrapper>
      <div className="text-center">
        {state === State.PENDING && <Spinner />}
        {error && <p className="text-danger">{error.message}</p>}
        {state === State.SUCCESS &&
          tenants.map((tenant) => (
            <Button success key={tenant.name} onClick={() => handleTenantSelection(tenant)}>
              {tenant.label}
            </Button>
          ))}
      </div>
    </LoginWrapper>
  );
};

export default TenantSelection;
