import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import usePrivileges from 'hooks/usePrivileges';

import { CrudMappings } from 'types/PrivilegeMappings';
import { State } from 'types/State';

import { havePrivilege } from 'utils/privileges';

import makeSelectAuthRequired from 'store/auth/selectors';
import { featureEnabled } from 'utils/features';
import { Features } from 'types/Features';
import history from 'utils/history';

/**
 * Auth Required component will be replaced with *better* react-router v5 implementation
 */
function AuthRequired<P>(Component: React.ComponentType<P>, access?: string[]) {
  return function AuthRequiredWrapper(props: P) {
    const auth = useSelector(makeSelectAuthRequired());
    const privileges = usePrivileges();

    useEffect(() => {
      // not authorized, go to login page
      if (!auth.loggedIn && auth.state === State.SUCCESS) {
        if (featureEnabled(Features.AuthV2)) {
          history.push('/auth');
        } else history.push('/login');
      }
    }, [auth.loggedIn, auth.state]);

    if (!auth.loggedIn) {
      return null;
    }

    if (access && !havePrivilege(access as any, [CrudMappings.read], privileges)) {
      return null;
    }
    return <Component privileges={privileges} AuthRequired={auth} {...props} />;
  };
}

export default AuthRequired;
