import React from 'react';

import RegisterByPhone, { RegisterPhoneFields } from 'Login/components/RegisterByPhone/RegisterByPhone';
import SmsVerification from 'Login/components/Smsverification/SmsVerification';
import { RegisterV2Step, registerV2Step1Thunk, registerV2Step3Thunk } from './slice';
import LoginWrapper from 'Login/components/LoginWrapper/LoginWrapper';
import useReducerThunk from 'hooks/useReducerThunk';
import registerV2Reducer, { initialState } from './slice';
import { State } from 'types/State';
import { OverrideNavigation } from 'Login/AuthV2/types';

const RegisterV2: React.FC<OverrideNavigation> = () => {
  const [{ attempts, step, phoneNumber, state }, dispatch] = useReducerThunk(registerV2Reducer, initialState);

  const handleRegisterByPhoneSubmit = async (values: RegisterPhoneFields) => {
    dispatch(
      registerV2Step1Thunk({ username: values.userName, phoneNumber: values.phoneNumber, password: values.password }),
    );
  };

  const handleSmsVerificationSubmit = async (payload: { token: string }) => {
    dispatch(registerV2Step3Thunk({ token: payload.token, phoneNumber }));
  };

  return (
    <LoginWrapper>
      {step === RegisterV2Step.DEVICE_REGISTRATION && (
        <RegisterByPhone loading={state === State.PENDING} onSubmit={handleRegisterByPhoneSubmit} />
      )}
      {step === RegisterV2Step.SMS_VERIFICATION && (
        <SmsVerification onSubmit={handleSmsVerificationSubmit} loading={state === State.PENDING} attempts={attempts} />
      )}
    </LoginWrapper>
  );
};

export default RegisterV2;
