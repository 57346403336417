/**
 *  Login system will be renewed on the backend in the future. This file points to those endpoints
 *  For now, only the DVV smartcard login is implemented.
 */
import { SmartCardStep2Payload } from 'types/Atostek';
import { LoginMethod, LoginMethodSelection, PhaseId, Tenant } from 'types/AuthenticationV2';

import api from 'utils/api';
import { SRP2Response, SRPResponse } from 'types/Authentication';

// This is for normal login
export const getV2Auth = () => api.get<{ goToPhaseId: PhaseId }>('authentication');

export const getLoginMethodSelection = () => api.get<LoginMethodSelection[]>('authentication/login');
export const loginMethodSelection = (payload: { loginMethodId: LoginMethod }) =>
  api.post('authentication/login', payload);

/*
 * This is for DVV smarcard login
 * */
export const getSmartCardChallenge = () => api.post<{ challenge: string }>('authentication/login/dvv_card');

export const postSmartCardStep2 = (data: SmartCardStep2Payload) =>
  api.post('authentication/login/dvv_card/step2', data);

export const postLoginV2Srpstep1 = (payload: { username: string; implementation: string }) =>
  api.post<SRPResponse>('authentication/login/srp', payload);

export const postLoginV2Srpstep2 = (payload: { AHex: string; M1Hex: string }) =>
  api.post<SRP2Response>('authentication/login/srp/step2', payload);

export const getDeviceRegistrations = () =>
  api.get<{ label: string; name: string }[]>('authentication/deviceregistrations');
export const postTenantSelection = ({ name }: Pick<Tenant, 'name'>) =>
  api.post('authentication/tenantselection', { name });
